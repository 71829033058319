import { differenceInSeconds } from 'owa-datetime';
import { formatShortTime } from 'owa-datetime-formatters';
import abbreviatedFormatDuration from 'owa-duration-formatter/lib/abbreviated';
import React from 'react';
import { makeStyles, mergeClasses, tokens } from '@fluentui/react-components';
import { SECONDS_IN_DAY } from '../constants';

import type { OwaDate } from 'owa-datetime';
export interface TimeContainerProps {
    className: string;
    currentDate: OwaDate;
    startTime: OwaDate;
    endTime: OwaDate;
    hideLength?: boolean;
}

const useStyles = makeStyles({
    timeContainer: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: tokens.fontSizeBase200,
        rowGap: '6px',
    },
    timeText: {
        width: '56px',
        fontSize: '12px',
    },
    lengthText: {
        color: tokens.colorNeutralForeground2,
    },
});

export default function TimeContainer(props: TimeContainerProps) {
    const classes = useStyles();
    const { className, currentDate, startTime, endTime, hideLength } = props;
    const allDayOrLongerEvent = differenceInSeconds(endTime, startTime) >= SECONDS_IN_DAY;

    return (
        <div className={mergeClasses(className, classes.timeContainer)}>
            <span className={classes.timeText}>
                {allDayOrLongerEvent ? formatShortTime(currentDate) : formatShortTime(startTime)}
            </span>
            {!hideLength && (
                <span className={mergeClasses(classes.timeText, classes.lengthText)}>
                    {endTime && startTime
                        ? abbreviatedFormatDuration(differenceInSeconds(endTime, startTime), {}, [
                              'minute',
                              'hour',
                              'second',
                              'day',
                          ])
                        : ''}
                </span>
            )}
        </div>
    );
}
