import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { DatapointStatus, PerformanceDatapoint } from 'owa-analytics';
import { getGuid } from 'owa-guid';
import { isSuccessStatusCode } from 'owa-http-status-codes';
import { makePostRequest } from 'owa-ows-gateway';

import type { UserEmailAddress } from 'accelerator-aad/lib/UserEmailAddress';

import type { MailboxInfo } from 'owa-client-types';
import type { GetPeopleInsightsRequestType } from '../types/GetPeopleInsightsRequestType';
import type { PeopleInsight } from '../types/PeopleInsight';
import type { Insight } from '../types/Insight';
const GET_PEOPLE_INSIGHTS_ENDPOINT = 'outlookgatewayb2/placesIntelligence/v2.0/peopleInsights';
const RARE_VISIT_KEY: Insight = 'RareVisit';

export default async function fetchCopilotPeopleInsights(
    requestBody: GetPeopleInsightsRequestType
): Promise<Map<UserEmailAddress, string[]>> {
    const body = {
        timeRange: {
            start: {
                dateTime: requestBody.timeRange.start,
                timeZone: requestBody.timeRange.start.tz,
            },
            end: {
                dateTime: requestBody.timeRange.end,
                timeZone: requestBody.timeRange.end.tz,
            },
        },
        numberOfWeeksToLookBack: requestBody.numberOfWeeksToLookBack,
    };

    const perfDatapoint = new PerformanceDatapoint('getCopilotPeopleInsights');
    let response: Response;
    const correlationId = getGuid();
    perfDatapoint.addCustomData({ correlationId });
    try {
        const mailboxInfo: MailboxInfo = getGlobalSettingsAccountMailboxInfo();
        response = await makePostRequest(
            GET_PEOPLE_INSIGHTS_ENDPOINT,
            body,
            correlationId /* correlationId */,
            true /* returnFullResponse */,
            undefined /* customHeaders */,
            true /* throwServiceError */,
            true /* sendPayloadAsBody */,
            undefined /*loadCredentials */,
            'peopleInsights' /* apiName */,
            undefined /* dataPointName */,
            mailboxInfo
        );
    } catch (error) {
        perfDatapoint.endWithError(DatapointStatus.RequestNotComplete, error);
        throw error;
    }

    if (!!response && isSuccessStatusCode(response.status)) {
        perfDatapoint.addCustomData({ status: response?.status });

        const responseObj = await response.json();
        perfDatapoint.end();
        const people: [PeopleInsight] = responseObj.people;
        const peopleInsights = new Map<UserEmailAddress, string[]>();
        for (var person of people) {
            const lowerCasePerson = person.email.toLowerCase();
            if (RARE_VISIT_KEY in person.insights) {
                peopleInsights.set(
                    lowerCasePerson as UserEmailAddress,
                    person.insights[RARE_VISIT_KEY].buildingIds as string[]
                );
            }
        }
        return peopleInsights;
    } else {
        const status = response?.status;
        const msCV = response?.headers?.get('ms-cv') ?? '';
        const requestId = response?.headers?.get('request-id') ?? '';

        perfDatapoint.addCustomData({ status, msCV, requestId });
        perfDatapoint.endWithError(DatapointStatus.ServerError);

        throw new Error('Failed to fetch people insights');
    }
}
