import { lazyHybridspaceWorkWeekCalendarSurfaceModule } from 'hybridspace-calendar';
import { lazyWorkplanModule } from 'hybridspace-home';
import { getWorkplanFWH } from 'hybridspace-home-app-store';
import { logGreyError, logUsage } from 'owa-analytics';
import { createDateRange } from 'owa-datetime-utils';
import { loadFWHAsync, loadPlaces } from 'places-explore-operations';
import {
    hybridGuidancePageLoadAction,
    placesFwkRootComponentMountedAction,
} from 'places-fwk-actions';
import { loadPlacesPeopleModule } from 'places-people';
import { getCurrentDate, getCurrentDateRange } from 'places-user-session-stores';
import { lazyPlacesWorkGroupsStoreModule, viewWorkPlan } from 'places-work-groups-store';

import type { LoaderFunction } from 'react-router-dom';
// Loader for the work-plan work groups route
// (/people/workgroup/:id)
export const WorkplanWorkGroupsLoader: LoaderFunction = async (...args) => {
    hybridGuidancePageLoadAction('Start');

    // starting agenda panel marker here as it is the first function before react render starts.
    placesFwkRootComponentMountedAction('PlacesAgendaPanelRoot');

    // When coming from a prefetcher (we are preloading the people tab) we should not throw an error
    let shouldNotThrowError = false;
    try {
        const [loaderArgs] = args;
        const requestUrl = loaderArgs.request.url;
        const url = new URL(requestUrl);
        shouldNotThrowError = url.searchParams.get('throwError')?.toLowerCase() === 'true';
    } catch (e) {
        logUsage('PlacesWorkplanWorkGroupsLoaderFetchURLError', { error: e });
    }

    // Always run this to handle swapping between groups
    viewWorkPlan(...args);

    const fwh = getWorkplanFWH();
    // Skip critical data
    if (fwh != undefined) {
        hybridGuidancePageLoadAction('FWHLoaded');
        return null;
    }

    //non critical data
    loadPlaces();

    // Define critical data: workplan module, and FWH for the week
    const fetchFWH = loadFWHAsync(getCurrentDateRange());
    const fetchTodayFWH = loadFWHAsync(createDateRange(getCurrentDate(), 1));
    const workplanModule = lazyWorkplanModule.import();
    const calendarModule = lazyHybridspaceWorkWeekCalendarSurfaceModule.import();
    const mergedModule = loadPlacesPeopleModule();
    const workgroupsStoreModule = lazyPlacesWorkGroupsStoreModule.import();

    const criticalData: Promise<any>[] = [
        workplanModule,
        fetchFWH,
        fetchTodayFWH,
        calendarModule,
        mergedModule,
        workgroupsStoreModule,
    ];

    try {
        const results = await Promise.all(criticalData);
        hybridGuidancePageLoadAction('End');
        return results;
    } catch (error) {
        logGreyError('WorkplanWorkGroupsLoader', error);
        // If coming from prefetcher, we will not throw an error, see PlacesRootRouteOutletContainer.tsx
        if (!shouldNotThrowError) {
            hybridGuidancePageLoadAction('End');
            return null;
        }
        hybridGuidancePageLoadAction('End');
        throw error;
    }
};
