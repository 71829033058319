import { FluentButton } from 'owa-fluent-v9-shims';
import React from 'react';
import {
    centered,
    errorReport,
    checkNetworkConnection,
    buttons,
    button,
    newPadding,
} from './styles.scss';
import { checkNetworkConnectionText } from './NetworkError.locstring.json';
import { getIllustration, type IllustrationType } from 'owa-empty-state/lib/utils/getIllustration';
import { getIsDarkTheme } from 'owa-fabric-theme';
import { observer } from 'owa-mobx-react';
import { retryButtonText } from 'owa-locstrings/lib/strings/retrybuttontext.locstring.json';
import { cancelButton } from 'owa-locstrings/lib/strings/cancelbutton.locstring.json';
import loc from 'owa-localize';
import classnames from 'owa-classnames';

const newStyle = {
    width: '200px',
    height: '200px',
};

export default observer(function NetworkError({
    errorMessage,
    onRetry,
    onCancel,
}: {
    errorMessage?: string;
    onRetry?: () => void;
    onCancel?: () => void;
}) {
    // Need these callbacks to ensure onRetry/onCancel are called without the event source argument.
    const retryCallback = React.useCallback(() => {
        onRetry?.();
    }, [onRetry]);

    const cancelCallback = React.useCallback(() => {
        onCancel?.();
    }, [onCancel]);

    const getNetworkErrorImageSrc = (): string => {
        return getIllustration('GenericError', getIsDarkTheme());
    };

    const imageComponent = (
        <img key="img" style={newStyle} src={getNetworkErrorImageSrc()} alt="" />
    );

    const mainMessageComponent = (
        <div key="mainMessage" className={classnames(errorReport, newPadding)}>
            {errorMessage}
        </div>
    );

    const metaMessageComponent = (
        <div key="metaMessage" className={checkNetworkConnection}>
            {loc(checkNetworkConnectionText)}
        </div>
    );

    const componentsToRender = [mainMessageComponent, imageComponent, metaMessageComponent];
    return (
        <div className={centered}>
            {componentsToRender}
            <div className={buttons}>
                {onRetry && (
                    <FluentButton appearance="primary" className={button} onClick={retryCallback}>
                        {loc(retryButtonText)}
                    </FluentButton>
                )}
                {onCancel && (
                    <FluentButton className={button} onClick={cancelCallback}>
                        {loc(cancelButton)}
                    </FluentButton>
                )}
            </div>
        </div>
    );
},
'NetworkError');
