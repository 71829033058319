import { getRareVisitData } from '../store';
import { getCurrentPlace } from './getCurrentPlace';
import type { UserEmailAddress } from 'accelerator-aad/lib/UserEmailAddress';

import { isFeatureEnabled } from 'owa-feature-flags';

export function isRareVisit(emailAddress: UserEmailAddress): boolean {
    const rareVisitData = getRareVisitData().get(emailAddress);
    if (!rareVisitData || rareVisitData.length === 0) {
        // if there is no data for that collaborator, then we cannot determine rare visit yet
        return false;
    }

    const currentPlace = getCurrentPlace();
    const currentPlaceId = currentPlace?.id;

    if (currentPlaceId) {
        if (isFeatureEnabled('msplaces-explore-people-rare-visits-copilot')) {
            return rareVisitData.includes(currentPlaceId);
        } else {
            return !rareVisitData.includes(currentPlaceId);
        }
    } else {
        return false;
    }
}
