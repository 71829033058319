import type { UserEmailAddress } from 'accelerator-aad/lib/UserEmailAddress';
import { addDays, startOfWeek, subWeeks } from 'owa-datetime';
import type { OwaDate } from 'owa-datetime';
import type { DateRange } from 'owa-datetime-utils';
import getScheduleWithLocation from 'hybridspace-common/lib/services/getScheduleWithLocation';
import { getDateRangeISOString } from 'hybridspace-common/lib/utils/getDateRangeISOString';
import { DatapointStatus, PerformanceDatapoint } from 'owa-analytics';
import { isFeatureEnabled } from 'owa-feature-flags';
import { createDateRange } from 'owa-datetime-utils';
import { getUserConfiguration } from 'owa-session-store';

import type {
    HybridspaceGetScheduleFlexibleWorkingHoursView,
    HybridspaceScheduleInformation,
} from 'owa-graph-schema';
import type { GetPeopleInsightsRequestType } from './types/GetPeopleInsightsRequestType';
import fetchCopilotPeopleInsights from './services/fetchCopilotPeopleInsights';
const NUMBER_OF_PAST_WEEKS = 2;

function getStartOfWeek(date: OwaDate) {
    const userStartOfWeek = getUserConfiguration().UserOptions?.WeekStartDay || 0;
    return startOfWeek(date, userStartOfWeek);
}

/**
 * Load buildings not visited in the past to
 * Load buildings visited in the past two weeks, starting from the beginning of the current week.
 * For example, if the user's week starts on Monday and today is Wednesday,
 * the range will be from Monday two weeks ago to the most recent Monday.
 * We then collect all buildings visited during this period by checking their schedules.
 */
function getPastWeeksDateRange(dateRange: DateRange): DateRange {
    // Get start of week, and subtract one day
    const firstDayOfWeek = addDays(getStartOfWeek(dateRange.start), -1);
    // Set the past date to two weeks before the start of the week
    const pastDateStart = subWeeks(firstDayOfWeek, 2); // Go back 14 days from the start of week
    // Create the date range from pastDateStart, for 14 days (2 weeks span)
    return createDateRange(pastDateStart, NUMBER_OF_PAST_WEEKS * 7 + 1);
}

export default async function fetchAllPeopleInsights(
    dateRange: DateRange,
    collaborators: UserEmailAddress[]
): Promise<Map<UserEmailAddress, string[]>> {
    const perfDatapoint = new PerformanceDatapoint('PlacesFetchPeopleInsights');
    const shouldCallCopilotService = isFeatureEnabled(
        'msplaces-explore-people-rare-visits-copilot'
    );

    perfDatapoint.addCustomData({
        dateRange: getDateRangeISOString(dateRange),
        version: shouldCallCopilotService ? 'copilotpeopleinsight' : 'clientpeopleinsight',
    });

    if (shouldCallCopilotService) {
        const getPeopleInsightsRequestBody: GetPeopleInsightsRequestType = {
            timeRange: dateRange,
            numberOfWeeksToLookBack: NUMBER_OF_PAST_WEEKS,
        };

        return fetchCopilotPeopleInsights(getPeopleInsightsRequestBody);
    } else {
        const pastDateRange = getPastWeeksDateRange(dateRange);

        // Initialize the map that will store <collaborator's email, array of building Ids>
        const collaboratorToBuildingMap = new Map<UserEmailAddress, string[]>();
        const collaboratorsWithInvalidSchedules: string[] = [];
        try {
            // Fetch schedules
            const schedules = await getScheduleWithLocation(collaborators, pastDateRange);

            collaborators.map(collaborator => {
                // Filter out any undefined or null schedule options before finding the correct schedule
                const validSchedules = schedules?.filter(
                    (scheduleOption): scheduleOption is HybridspaceScheduleInformation =>
                        scheduleOption !== undefined && scheduleOption !== null
                );

                // If there are no valid schedules, return early bc we dont have enough information
                // to determine a rare visit
                if (
                    validSchedules === undefined ||
                    validSchedules === null ||
                    validSchedules.length === 0
                ) {
                    collaboratorsWithInvalidSchedules.push(collaborator);
                    return;
                }

                const schedule = validSchedules?.find(
                    (scheduleOption: HybridspaceScheduleInformation) =>
                        scheduleOption.scheduleId === collaborator
                );

                const schedulesCorrupted = schedule?.error != undefined;
                if (!schedulesCorrupted) {
                    // Collect building IDs from the collaborator's flexible working hours
                    const buildingIds: string[] =
                        schedule?.flexibleWorkingHours
                            ?.map(
                                (
                                    flexibleWorkingHour: HybridspaceGetScheduleFlexibleWorkingHoursView
                                ) => flexibleWorkingHour.building?.id
                            )
                            .filter((id): id is string => !!id) || []; // Filter out undefined and ensure the result is a string[]

                    const uniqueBuildingIds = new Set(buildingIds);
                    const lowerCaseCollaborator = collaborator.toString().toLowerCase();
                    collaboratorToBuildingMap.set(
                        lowerCaseCollaborator as UserEmailAddress,
                        Array.from(uniqueBuildingIds)
                    );
                }
            });
        } catch (error) {
            // Handle any errors that occur during the scheduling fetch process
            perfDatapoint.endWithError(DatapointStatus.ServerError, error);
            throw error;
        }

        perfDatapoint.addCustomData({
            collaboratorsWithInvalidSchedules: collaboratorsWithInvalidSchedules.length,
        });
        perfDatapoint.end();

        return collaboratorToBuildingMap;
    }
}
