import { fetch } from 'owa-fetch';
import { logGreyError, logUsage } from 'owa-analytics';
import { isFeatureEnabled } from 'owa-feature-flags';
import { isSuccessStatusCode } from 'owa-http-status-codes';
import isRetriableAuthError from 'owa-service/lib/isRetriableAuthError';
import { buildAuthorizationHeader } from 'owa-tokenprovider-utils';
import { MSGRAPH_RESOURCE } from '../urls/MSGraph';
import { getAuthToken } from '../utils/getAuthToken';

import type { GraphUsersResponse } from '../types/GraphUsersResponse';

const MAX_RETRY_COUNT = 1;

export async function fetchIdsFromMsGraph<T = GraphUsersResponse[]>(
    smtpQueryString: string,
    correlationId: string
): Promise<T> {
    const token = await getAuthToken(
        MSGRAPH_RESOURCE, // resource
        'idFetchFromMSGraph', // apiName
        correlationId // requestId
    );

    // Build string with all the smtps to search for this group
    const GET_MSGRAPH_SEARCH_URL: string = `${MSGRAPH_RESOURCE}v1.0/users?$select=id,mail&$count=true&$filter=mail+in(${smtpQueryString})`;

    const options = {
        method: 'GET',
        headers: {
            Authorization: buildAuthorizationHeader(token),
            Host: 'graph.microsoft.com',
            ConsistencyLevel: 'eventual',
        },
    };
    return handleFetchWithRetry<T>(
        GET_MSGRAPH_SEARCH_URL,
        options,
        MAX_RETRY_COUNT,
        (response: Response) =>
            getAuthToken(MSGRAPH_RESOURCE, 'idFetchFromMSGraph', correlationId, response)
    );
}

async function handleFetchWithRetry<T>(
    apiEndpoint: string,
    options: RequestInit,
    retryCount: number = MAX_RETRY_COUNT,
    getAuthTokenRetry?: (response: Response) => Promise<string>
): Promise<T> {
    return fetch(apiEndpoint, options)
        .then(async response => {
            if (
                isFeatureEnabled('msplaces-presenceservice-authretry') &&
                isRetriableAuthError(response.status, true /** authNeededOnUnAuthorized */) &&
                retryCount > 0 &&
                getAuthTokenRetry
            ) {
                const authToken = await getAuthTokenRetry(
                    response // to fetch new token if it's invalid.
                );
                const newOption = {
                    ...options,
                    headers: {
                        ...options.headers,
                        Authorization: buildAuthorizationHeader(authToken),
                    },
                };
                return handleFetchWithRetry<T>(apiEndpoint, newOption, retryCount - 1);
            }

            if (!isSuccessStatusCode(response.status)) {
                // If the response is not success and not retriable, return the response
                logUsage('PresenceServiceFetchIdsFromMsGraph_FetchUnexpectedResponse', {
                    status: response.status,
                    statusText: response.statusText,
                    correlationId: response.headers.get('x-ms-correlation-id'),
                    cV: response.headers.get('ms-cv'),
                });
            }

            const jsonResponse = await response.json();
            return jsonResponse.value as T; // returning array that can be empty
        })
        .catch(error => {
            logGreyError('PresenceServiceFetchIdsFromMsGraph_FetchError', error, {
                apiEndpoint,
                retryCount,
                correlationId: error?.response?.headers?.get('x-ms-correlation-id'),
                cV: error?.response?.headers?.get('ms-cv'),
            });
            throw error;
        });
}
