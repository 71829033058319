import { collaboratorsFetchedAction } from 'hybridspace-common/lib/shared-actions';
import { loadUpsCollaboratorsBuildingLocations } from 'places-ups-location-operations';
import { getUpsLastServiceSuccessfullyCompleted } from 'places-ups-location-store';
import { orchestrator } from 'satcheljs';

orchestrator(collaboratorsFetchedAction, ({ collaborators, category }) => {
    if (!getUpsLastServiceSuccessfullyCompleted(category)) {
        loadUpsCollaboratorsBuildingLocations(collaborators, category);
    }
});
