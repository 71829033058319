import { createLazyComponent, LazyAction, LazyImport, LazyModule } from 'owa-bundling';

import type { ShouldRevalidateFunction } from 'react-router-dom';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "PlacesWorkGroups" */ './lazyIndex')
);

// Typescript linter doesn't like non-typed "m", so need to shape the input to avoid linter complaints.
export const WorkGroupsDashboard = createLazyComponent(
    lazyModule,
    (m: { WorkGroupsDashboard: any }) => m.WorkGroupsDashboard
);
export const NoWorkGroupsFound = createLazyComponent(
    lazyModule,
    (m: { NoWorkGroupsFound: any }) => m.NoWorkGroupsFound
);
export const WorkGroupsModuleHeader = createLazyComponent(
    lazyModule,
    (m: { WorkGroupsModuleHeader: any }) => m.WorkGroupsModuleHeader
);
export const CollaboratorsViewToggle = createLazyComponent(
    lazyModule,
    m => m.CollaboratorsViewToggle
);

export const PlacesTeamWorkPlan = createLazyComponent(lazyModule, m => m.PlacesTeamWorkPlan);

export const WORKGROUPS_ROOT = 'workgroups';

const lazyLoadAllWorkGroupsAction = new LazyAction(lazyModule, m => m.loadAllWorkGroupsAction);

export function loadAllWorkGroupsAsync() {
    return lazyLoadAllWorkGroupsAction.importAndExecute();
}
// LazyImport because we don't need importAndExecute
const lazyShouldAllWorkGroupsRevalidate = new LazyImport(
    lazyModule,
    m => m.shouldAllWorkGroupsRevalidate
);

export const allWorkGroupsRevalidate: ShouldRevalidateFunction = ({
    currentUrl,
    nextUrl,
    ...restOfArgs
}) => {
    // Revalidate if we think it should revalidate based off of the status.
    // We are using `tryImportForRender` since we need the function to be synchronous,
    // and we can fallback onto checking if the pathname is the same as the "first"
    // time we run this function
    return (
        lazyShouldAllWorkGroupsRevalidate.tryImportForRender()?.({
            currentUrl,
            nextUrl,
            ...restOfArgs,
        }) ?? currentUrl.pathname == nextUrl.pathname
    );
};

const lazyAreAllWorkGroupsLoaded = new LazyImport(lazyModule, m => m.areAllWorkGroupsLoaded);
export const areAllWorkGroupsLoaded = () =>
    lazyAreAllWorkGroupsLoaded.tryImportForRender() ?? false;
