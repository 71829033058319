import { DatapointStatus, PerformanceDatapoint } from 'owa-analytics';
import { getSessionId } from 'owa-config';
import { fetchIdsFromMsGraph } from './fetchIdsFromMsGraph';

import type { GraphUsersResponse } from '../types/GraphUsersResponse';

const GRAPH_GROUP_SIZE = 200;

export async function getMRIsFromGraphInBulk(smtps: string[]): Promise<GraphUsersResponse[]> {
    const mrisToReturn: GraphUsersResponse[] = [];
    const correlationId = getSessionId();

    const performanceMarker = new PerformanceDatapoint('PresenceGetMrisFromGraphInBulk');
    performanceMarker.addCustomData({ correlationId, requestedCount: smtps.length });

    if (smtps) {
        // Break up the emails into groups of 200 to avoid hitting the max url length
        const groups = [];
        if (smtps.length > GRAPH_GROUP_SIZE) {
            for (let i = 0; i < smtps.length; i += GRAPH_GROUP_SIZE) {
                groups.push(smtps.slice(i, i + GRAPH_GROUP_SIZE));
            }
        } else {
            groups.push(smtps);
        }

        // Fire off requests for each group in parallel
        const responsePromises = [];
        for (const group of groups) {
            // Build string with all the smtps to search for this group
            const smtpsToQueryString = buildStringWithSmtpsToQuery(group);
            // If the smtpsToQueryString is over 10000 chars, we need to break it up into two groups
            if (smtpsToQueryString.length > 10000) {
                const half = Math.floor(group.length / 2);
                const firstHalf = group.slice(0, half);
                const secondHalf = group.slice(half);
                groups.push(firstHalf, secondHalf);
            } else {
                responsePromises.push(
                    fetchIdsFromMsGraph<GraphUsersResponse[]>(smtpsToQueryString, correlationId)
                );
            }
        }

        // Wait for all the responses to come back and handle telemetry
        const results = await Promise.allSettled(responsePromises);
        let totalFulfilled = 0;
        let totalRejected = 0;
        const rejectionReasons: string[] = [];
        results.forEach(result => {
            if (result.status === 'fulfilled') {
                totalFulfilled++;
                const response = result.value;
                if (response) {
                    mrisToReturn.push(...response);
                }
            } else {
                totalRejected++;
                rejectionReasons.push(result.reason);
            }
        });

        performanceMarker.addCustomData({
            fulfilledResponses: totalFulfilled,
            rejectedResponses: totalRejected,
            rejectionReasons: safeStringify(rejectionReasons),
        });

        if (totalRejected > 0) {
            const error = new Error('Presence: Failed to fetch MRIs from Graph. Check MiscData.');
            performanceMarker.endWithError(DatapointStatus.ServerError, error);
        } else {
            performanceMarker.end();
        }
    }
    return mrisToReturn;
}

function buildStringWithSmtpsToQuery(smtps: string[]): string {
    const smtpsToQuery = smtps.map(smtp => `"${encodeURIComponent(smtp)}"`).join(',');
    return smtpsToQuery;
}

function safeStringify(input: string[]): string {
    try {
        return JSON.stringify(input);
    } catch (e) {
        return e.message;
    }
}
