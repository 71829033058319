import { setRecurringFWHInStore } from 'hybridspace-common/lib/utils';
import { getDateRangeISOString } from 'hybridspace-common/lib/utils/getDateRangeISOString';
import { createDateRange } from 'owa-datetime-utils';
import { loadFWHSucceeded } from 'places-explore-operations';
import { getCurrentDate, getCurrentDateRange } from 'places-user-session-stores';
import { orchestrator } from 'satcheljs';
import { setCurrentDayFWH, setWorkplanFWH } from './Store';

orchestrator(loadFWHSucceeded, ({ result, dateRange }) => {
    const key = getDateRangeISOString(dateRange);
    const currentDateRangeKey = getDateRangeISOString(getCurrentDateRange());
    const todayDateRange = getDateRangeISOString(createDateRange(getCurrentDate(), 1));
    // We make sure the result is for the current date range
    if (key === currentDateRangeKey) {
        setWorkplanFWH(result.occurringFlexibleWorkingHours);
    }
    // If the result is for today, we need to update the store with the current day FWH
    if (key === todayDateRange) {
        setCurrentDayFWH(result.occurringFlexibleWorkingHours);
    }
    setRecurringFWHInStore(result.recurringFlexibleWorkingHours);
});
