import type EventifyEvent from 'eventify-schema/lib/EventifyEvent';
import type EventsGroup from 'eventify-schema/lib/EventsGroup';
import type EnhancedLocationWithBuildingId from 'eventify-schema/lib/EnhancedLocationWithBuildingId';
import type RequestState from 'eventify-schema/lib/RequestState';
import { ObservableMap } from 'mobx';
import { LazyAction, LazyImport, LazyModule } from 'owa-bundling';
import { defer } from 'react-router-dom';
import initializeWorkGroupsComposeErrors from './utils/initializeWorkGroupsComposeErrors';

import type { OwaDateRange } from 'owa-datetime-utils/lib/OwaDateRange';
import type RoleType from 'eventify-schema/lib/RoleType';
import type { RecurrenceType } from 'owa-graph-schema';
import type { UserToAdd } from './orchestrators/currentEventsGroupOrchestrators';
import type UserInfo from 'eventify-schema/lib/UserInfo';
import type EventWithMaster from './store/schema/EventWithMaster';
import type { WorkGroupMinimized } from './store/schema/MinimizedWorkGroup';
import type WorkGroupsComposeErrors from './store/schema/WorkGroupsComposeErrors';
import type { LoaderFunction, NavigateFunction } from 'react-router-dom';
// Utils direct exports
export { default as getCurrentEventState } from './utils/getCurrentEventState';
export { isValidGroupAdministratorsCount } from './utils/isValidGroupAdministrators';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "PlacesWorkGroupsStore" */ './lazyIndex')
);

export const lazyPlacesWorkGroupsStoreModule = new LazyImport(lazyModule, m => m);

const lazyGetCurrentEvent = new LazyAction(lazyModule, m => m.getCurrentEvent);
const lazyGetCurrentEventsGroup = new LazyAction(lazyModule, m => m.getCurrentEventsGroup);
const lazyGetGuidanceInstancesWithMasters = new LazyAction(
    lazyModule,
    m => m.getGuidanceInstancesWithMasters
);
const lazyGetLoadGuidancesInstancesRequestState = new LazyAction(
    lazyModule,
    m => m.getLoadGuidancesInstancesRequestState
);
const lazyGetLoadAllWorkGroupsRequestState = new LazyAction(
    lazyModule,
    m => m.getLoadAllWorkGroupsRequestState
);
const lazyGetOriginalEventsGroup = new LazyAction(lazyModule, m => m.getOriginalEventsGroup);
const lazyGetWorkGroupChangesMade = new LazyAction(lazyModule, m => m.getWorkGroupChangesMade);
const lazyGetWorkGroups = new LazyAction(lazyModule, m => m.getWorkGroups);
const lazyGetWorkGroupsComposeErrors = new LazyAction(
    lazyModule,
    m => m.getWorkGroupsComposeErrors
);

// Functions for exposing store setters and getters
export function getCurrentEvent(): EventifyEvent | null {
    return lazyGetCurrentEvent.tryImportForRender()?.() ?? null;
}

export function getCurrentEventsGroup(): EventsGroup | null {
    return lazyGetCurrentEventsGroup.tryImportForRender()?.() ?? null;
}

export function getGuidanceInstancesWithMasters(): EventWithMaster[] | null {
    return lazyGetGuidanceInstancesWithMasters.tryImportForRender()?.() ?? null;
}

export function getLoadGuidancesInstancesRequestState(): RequestState {
    return lazyGetLoadGuidancesInstancesRequestState.tryImportForRender()?.() ?? 0;
}

export function getLoadAllWorkGroupsRequestState(): RequestState {
    return lazyGetLoadAllWorkGroupsRequestState.tryImportForRender()?.() ?? 0;
}

export function getOriginalEventsGroup(): EventsGroup | null {
    return lazyGetOriginalEventsGroup.tryImportForRender()?.() ?? null;
}

export function getWorkGroupChangesMade(): boolean {
    return !!lazyGetWorkGroupChangesMade.tryImportForRender()?.();
}

export function getWorkGroups(): ObservableMap<string, WorkGroupMinimized> {
    return (
        lazyGetWorkGroups.tryImportForRender()?.() ??
        new ObservableMap<string, WorkGroupMinimized>()
    );
}

export function getWorkGroupsComposeErrors(): WorkGroupsComposeErrors {
    return (
        lazyGetWorkGroupsComposeErrors.tryImportForRender()?.() ??
        initializeWorkGroupsComposeErrors()
    );
}

// Lazy exports for currentEventActions
const lazySetGuidanceInformation = new LazyAction(lazyModule, m => m.setGuidanceInformation);
const lazyUpdateCurrentEventContentAction = new LazyAction(
    lazyModule,
    m => m.updateCurrentEventContentAction
);
const lazyUpdateCurrentEventTitleAction = new LazyAction(
    lazyModule,
    m => m.updateCurrentEventTitleAction
);
const lazyUpdateCurrentEventRecurrenceAction = new LazyAction(
    lazyModule,
    m => m.updateCurrentEventRecurrenceAction
);

// Lazy exports for dashboardActions
const lazySetCurrentEvent = new LazyAction(lazyModule, m => m.setCurrentEvent);
const lazySetWorkGroupChangesMade = new LazyAction(lazyModule, m => m.setWorkGroupChangesMade);
const lazySetLoadAllWorkGroupsRequestState = new LazyAction(
    lazyModule,
    m => m.setLoadAllWorkGroupsRequestState
);
const lazySetCurrentEventsGroupThemeImageIdPromise = new LazyAction(
    lazyModule,
    m => m.setCurrentEventsGroupThemeImageIdPromise
);
const lazyUpdateCurrentEventsGroupNameAction = new LazyAction(
    lazyModule,
    m => m.updateCurrentEventsGroupNameAction
);

// Lazy exports for locationsActions
const lazyUpdateCurrentEventLocationsAction = new LazyAction(
    lazyModule,
    m => m.updateCurrentEventLocationsAction
);
const lazyUpdateIsInPersonEventAction = new LazyAction(
    lazyModule,
    m => m.updateIsInPersonEventAction
);

// Lazy exports for workGroupsListActions
const lazySetAllWorkGroupsList = new LazyAction(lazyModule, m => m.setAllWorkGroupsList);

const lazyPublishCurrentEventsGroup = new LazyAction(
    lazyModule,
    m => m.publishCurrentEventsGroupAction
);
const lazyUpdateCurrentEventsGroupThemeImage = new LazyAction(
    lazyModule,
    m => m.updateCurrentEventsGroupThemeImageAction
);

const lazyValidateAndTryAddUserToAdministrators = new LazyAction(
    lazyModule,
    m => m.validateAndTryAddUserToAdministrators
);
const lazyValidateAndTryAddUserToMembers = new LazyAction(
    lazyModule,
    m => m.validateAndTryAddUserToMembers
);
const lazyValidateAndTryRemoveUserFromAdministrators = new LazyAction(
    lazyModule,
    m => m.validateAndTryRemoveUserFromAdministrators
);
const lazyValidateAndTryRemoveUserFromMembers = new LazyAction(
    lazyModule,
    m => m.validateAndTryRemoveUserFromMembers
);

const lazyUpdateXAmountOfUsersCouldNotBeAddedBecauseOfAdministratorMemberWell = new LazyAction(
    lazyModule,
    m => m.updateXAmountOfUsersCouldNotBeAddedBecauseOfAdministratorMemberWell
);
const lazyUpdateXAmountOfUsersCouldNotBeAddedBecauseOfAdministratorAdminsWell = new LazyAction(
    lazyModule,
    m => m.updateXAmountOfUsersCouldNotBeAddedBecauseOfAdministratorAdminsWell
);

const lazyCloseWorkGroupsCompose = new LazyAction(lazyModule, m => m.closeWorkGroupsCompose);
const lazyOpenNewWorkGroupCompose = new LazyAction(lazyModule, m => m.openNewWorkGroupCompose);
const lazyEditWorkGroupCompose = new LazyAction(lazyModule, m => m.editWorkGroupCompose);

const lazyLoadWorkGroupThemeThumbnails = new LazyAction(
    lazyModule,
    m => m.loadWorkGroupThemeThumbnails
);

const lazyConfirmPublishAction = new LazyAction(lazyModule, m => m.confirmPublishAction);
const lazyDeleteGuidance = new LazyAction(lazyModule, m => m.deleteGuidance);
const lazyOnCurrentEventStartEndDateTimeChange = new LazyAction(
    lazyModule,
    m => m.onCurrentEventStartEndDateTimeChange
);

const lazyUnfollowWorkGroupAsync = new LazyAction(lazyModule, m => m.unfollowWorkGroupAsync);
const lazyDeleteWorkGroupAsync = new LazyAction(lazyModule, m => m.deleteWorkGroupAsync);
const lazyNewGuidance = new LazyAction(lazyModule, m => m.newGuidance);

// Exports
export async function setGuidanceInformation(
    guidanceId: string,
    workgroupFollowerList: UserInfo[] | undefined
): Promise<void> {
    await lazySetGuidanceInformation.importAndExecute(guidanceId, workgroupFollowerList);
}

export async function updateCurrentEventContentAction(content: string): Promise<void> {
    await lazyUpdateCurrentEventContentAction.importAndExecute(content);
}

export async function updateCurrentEventTitleAction(title: string): Promise<void> {
    await lazyUpdateCurrentEventTitleAction.importAndExecute(title);
}

export async function updateCurrentEventRecurrenceAction(
    recurrenceType: RecurrenceType
): Promise<void> {
    await lazyUpdateCurrentEventRecurrenceAction.importAndExecute(recurrenceType);
}

export async function setCurrentEvent(event: EventifyEvent): Promise<void> {
    await lazySetCurrentEvent.importAndExecute(event);
}

export async function setWorkGroupChangesMade(changesMade: boolean): Promise<void> {
    await lazySetWorkGroupChangesMade.importAndExecute(changesMade);
}

export async function setLoadAllWorkGroupsRequestState(requestState: RequestState): Promise<void> {
    await lazySetLoadAllWorkGroupsRequestState.importAndExecute(requestState);
}

export async function setCurrentEventsGroupThemeImageIdPromise(
    themeImageIdPromise: Promise<string | null>
): Promise<void> {
    await lazySetCurrentEventsGroupThemeImageIdPromise.importAndExecute(themeImageIdPromise);
}

export async function updateCurrentEventsGroupNameAction(name: string): Promise<void> {
    await lazyUpdateCurrentEventsGroupNameAction.importAndExecute(name);
}

export async function updateCurrentEventLocationsAction(
    locations: EnhancedLocationWithBuildingId[]
): Promise<void> {
    await lazyUpdateCurrentEventLocationsAction.importAndExecute(locations);
}

export async function updateIsInPersonEventAction(isInPerson: boolean): Promise<void> {
    await lazyUpdateIsInPersonEventAction.importAndExecute(isInPerson);
}

export async function setAllWorkGroupsList(workGroups: WorkGroupMinimized[]): Promise<void> {
    await lazySetAllWorkGroupsList.importAndExecute(workGroups);
}

export function publishCurrentEventsGroup(): Promise<string | undefined> {
    return lazyPublishCurrentEventsGroup.importAndExecute();
}

export async function updateCurrentEventsGroupThemeImageAction(themeImage: string): Promise<void> {
    await lazyUpdateCurrentEventsGroupThemeImage.importAndExecute(themeImage);
}

export async function validateAndTryAddUserToAdministrators(
    roleType: RoleType,
    users: UserToAdd[]
): Promise<void> {
    await lazyValidateAndTryAddUserToAdministrators.importAndExecute(roleType, users);
}

export async function validateAndTryAddUserToMembers(
    roleType: RoleType,
    users: UserToAdd[]
): Promise<void> {
    await lazyValidateAndTryAddUserToMembers.importAndExecute(roleType, users);
}

export async function validateAndTryRemoveUserFromAdministrators(smtp: string): Promise<void> {
    await lazyValidateAndTryRemoveUserFromAdministrators.importAndExecute(smtp);
}

export async function validateAndTryRemoveUserFromMembers(smtp: string): Promise<void> {
    await lazyValidateAndTryRemoveUserFromMembers.importAndExecute(smtp);
}

export async function updateXAmountOfUsersCouldNotBeAddedBecauseOfAdministratorMemberWell(
    amount: number
): Promise<void> {
    await lazyUpdateXAmountOfUsersCouldNotBeAddedBecauseOfAdministratorMemberWell.importAndExecute(
        amount > 0,
        amount
    );
}

export async function updateXAmountOfUsersCouldNotBeAddedBecauseOfAdministratorAdminsWell(
    amount: number
): Promise<void> {
    await lazyUpdateXAmountOfUsersCouldNotBeAddedBecauseOfAdministratorAdminsWell.importAndExecute(
        amount > 0,
        amount
    );
}

export async function closeWorkGroupsCompose(): Promise<void> {
    await lazyCloseWorkGroupsCompose.importAndExecute();
}

export function openNewWorkGroupCompose(): Promise<void> {
    return lazyOpenNewWorkGroupCompose.importAndExecute();
}

export function editWorkGroupCompose(workGroupId: string): Promise<void> {
    return lazyEditWorkGroupCompose.importAndExecute(workGroupId);
}

export async function loadWorkGroupThemeThumbnails(
    workgroups: WorkGroupMinimized[]
): Promise<void> {
    await lazyLoadWorkGroupThemeThumbnails.importAndExecute(workgroups);
}

export async function confirmPublishAction(): Promise<boolean> {
    return lazyConfirmPublishAction.importAndExecute();
}

export async function deleteGuidance(guidanceId: string): Promise<void> {
    return lazyDeleteGuidance.importAndExecute(guidanceId);
}

export async function onCurrentEventStartEndDateTimeChange(
    startEndDateTime: OwaDateRange
): Promise<void> {
    await lazyOnCurrentEventStartEndDateTimeChange.importAndExecute(startEndDateTime);
}

export async function unfollowWorkGroupAsync(
    eventsGroupId: string,
    navigate: NavigateFunction
): Promise<void> {
    await lazyUnfollowWorkGroupAsync.importAndExecute(eventsGroupId, navigate);
}

export async function deleteWorkGroupAsync(eventsGroupId: string): Promise<void> {
    await lazyDeleteWorkGroupAsync.importAndExecute(eventsGroupId);
}

export async function newGuidance(): Promise<void> {
    await lazyNewGuidance.importAndExecute();
}

export const viewWorkPlan: LoaderFunction = async ({ params }) => {
    return defer({
        workgroup: lazyViewWorkPlans.importAndExecute(
            params.workgroupId
                ? {
                      id: params.workgroupId,
                  }
                : undefined
        ),
    });
};

const lazyViewWorkPlans = new LazyAction(lazyModule, m => m.viewWorkPlans);

const lazyMapServiceWorkGroupMinimizedToClientWorkGroupMinimized = new LazyAction(
    lazyModule,
    m => m.mapServiceWorkGroupMinimizedToClientWorkGroupMinimized
);

const lazyUploadImage = new LazyAction(lazyModule, m => m.uploadImage);
const lazyGetEventifyEvent = new LazyAction(lazyModule, m => m.getEventifyEvent);
const lazyGetEventsGroupFullChildEventsNextOccurrence = new LazyAction(
    lazyModule,
    m => m.getEventsGroupFullChildEventsNextOccurrence
);

export async function mapServiceWorkGroupMinimizedToClientWorkGroupMinimized(
    workGroup: any
): Promise<WorkGroupMinimized> {
    return lazyMapServiceWorkGroupMinimizedToClientWorkGroupMinimized.importAndExecute(workGroup);
}

export async function uploadImage(imageBlob: Blob | null, saveThumbnail: boolean) {
    return lazyUploadImage.importAndExecute(imageBlob, saveThumbnail);
}

export async function getEventifyEvent(
    id: string,
    correlationId: string,
    source: string
): Promise<EventifyEvent> {
    return lazyGetEventifyEvent.importAndExecute(id, correlationId, source);
}

export async function getEventsGroupFullChildEventsNextOccurrence(
    groupId: string
): Promise<EventWithMaster[] | null> {
    return lazyGetEventsGroupFullChildEventsNextOccurrence.importAndExecute(groupId);
}

export type { UserToAdd } from './orchestrators/currentEventsGroupOrchestrators';
export type { WorkGroupMinimized } from './store/schema/MinimizedWorkGroup';
