export function extractCommonResponseHeader(response: Response) {
    const wwwAuthenticateHeader = response?.headers?.get('www-authenticate') || 'No WWAuthHeader';
    const xMsDiagnosticsHeader =
        response?.headers?.get('x-ms-diagnostics') || 'No x-ms-diagnostics header';
    const cV = response?.headers?.get('ms-cv') || 'No ms-cv header';
    return {
        status: response.status,
        wwwAuthenticateHeader,
        xMsDiagnosticsHeader,
        cV,
    };
}
