import { useManagedQueryWithError } from 'hybridspace-graphql/lib/utils/useManagedQueryWithError';
import { logUsage } from 'owa-analytics';
import React from 'react';
import { GetMapFeaturesDocument } from '../graphql/__generated__/GetMapFeaturesQuery.interface';

import type {
    MapFeature,
    FloorPlan,
    Floor,
    FeatureType,
    CategoryType,
    LngLat,
    Geometry,
} from '../serviceTypes/FloorPlanTypes';
import type { ImdfFeatureType } from 'owa-graph-schema';

/**
 * Unit feature: conf room, desk pool, restroom, etc.
 * Level feature: floor footprint/outline
 */
const baseLayerFeatureTypes: ImdfFeatureType[] = ['Unit', 'Level'];

export default function useFloorPlan(floorId: string | null, skip: boolean = false) {
    const { data, loading, error } = useManagedQueryWithError(GetMapFeaturesDocument, {
        variables: {
            input: {
                ids: [
                    {
                        id: floorId,
                        idType: 'PlacesDirectory',
                        placeType: 'floor',
                    },
                ],
                featureTypes: baseLayerFeatureTypes,
            },
        },
        skip: skip || !floorId || floorId == 'none',
    });

    if (error) {
        const errorObject = error?.graphQLErrors?.find(e => {
            return e.extensions?.data?.['RpcException.StatusCode'] !== undefined;
        });
        const originalError = errorObject?.originalError;
        logUsage('PlacesMaps_GetMapFeaturesError', {
            error: error.message,
            name: error.name,
            code: errorObject?.extensions?.data?.['RpcException.StatusCode'],
            originalMessage: originalError?.message,
            originalStack: originalError?.stack,
            originalName: originalError?.name,
            stack: error.stack,
            floorId,
        });
    }

    // We're requesting map features for one floor at a time, so there should be only one element in mapFeatures.
    const mapFeatures = data?.getMapFeatures?.mapFeatures;
    const mapFeature = mapFeatures && mapFeatures.length > 0 && mapFeatures[0];
    const displayPoint = mapFeature && mapFeature.displayPoint?.coordinates;
    const displayCenter: LngLat =
        displayPoint && displayPoint.length == 2 && displayPoint[0] && displayPoint[1]
            ? {
                  lng: displayPoint[0],
                  lat: displayPoint[1],
              }
            : {
                  lng: 0,
                  lat: 0,
              };

    const floor: Floor = React.useMemo(() => {
        const floorPlan: FloorPlan = new Map();

        // Service should always return a display point for the features requested
        // However property in the schema is nullable, so we should consider {0,0} as invalid
        if (!displayCenter.lat && !displayCenter.lng) {
            logUsage('PlacesMaps_InvalidDisplayPoint', { floorId });
            return { floorId, floorPlan, displayCenter };
        }

        const floorFeatures = (mapFeature && mapFeature.features) || [];

        // Each element in the floor belongs to one IMDF feature type (units, amenities, etc.),
        // the value of each element is a blob of geoJSON.
        floorFeatures.forEach(featuresEntry => {
            if (!featuresEntry?.key || !featuresEntry?.value) {
                return;
            }

            const featureType = featuresEntry.key.toLowerCase() as FeatureType;
            const featuresBlob = featuresEntry.value;

            try {
                // Parse the geoJSON blobs into MapFeature objects.
                const features: MapFeature[] = JSON.parse(featuresBlob);
                const featuresByCategory = groupFeaturesByCategory(features);
                floorPlan.set(featureType, featuresByCategory);
            } catch (parseError) {
                logUsage('PlacesMaps_MapFeatureParseError', {
                    message: parseError.message,
                    stack: parseError.stack,
                    floorId,
                });
            }
        });

        return { floorId, floorPlan, displayCenter };
    }, [floorId, loading, error, skip]);

    return { floor, loading, error };
}

/**
 * Given an array of MapFeatures (under the same feature_type),
 * group the features by category, return a map of category -> features
 */
function groupFeaturesByCategory(features: MapFeature[]) {
    const featuresByCategory = new Map<CategoryType, MapFeature[]>();

    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    features.forEach(feature => {
        const category = feature.properties.places_category.toLowerCase() as CategoryType;

        // calculate the display center if the feature is of type conferenceroom or workspace and doesn't have a display center
        if (
            (category === 'conferenceroom' || category === 'workspace') &&
            !feature.properties.display_center
        ) {
            feature.properties.display_center = getGeometryCenter(feature.geometry);
        }

        feature.properties.label = getLabelText(feature);

        if (!featuresByCategory.has(category)) {
            featuresByCategory.set(category, []);
        }
        featuresByCategory.get(category)?.push(feature);
    });
    return featuresByCategory;
}

// calculate the center of the geometry for the unit
// this is a workaround until the server provide the center of the geometry for each unit
function getGeometryCenter(geometry?: Geometry): LngLat | undefined {
    if (
        !geometry ||
        geometry.type !== 'Polygon' ||
        !geometry.coordinates ||
        !geometry.coordinates[0]
    ) {
        return;
    }
    const coordinateList = geometry.coordinates[0];
    const center = getPolygonCentroid(coordinateList);
    return center;
}

function getPolygonCentroid(pts: GeoJSON.Position[]): LngLat {
    const first = pts[0];
    const last = pts[pts.length - 1];
    if (first[0] != last[0] || first[1] != last[1]) {
        pts.push(first);
    }

    let twicearea = 0;
    let x = 0;
    let y = 0;
    const nPts = pts.length;
    let f = 0;
    for (let i = 0, j = nPts - 1; i < nPts; j = i++) {
        const p1 = pts[i];
        const p2 = pts[j];
        f = (p1[1] - first[1]) * (p2[0] - first[0]) - (p2[1] - first[1]) * (p1[0] - first[0]);
        twicearea += f;
        x += (p1[0] + p2[0] - 2 * first[0]) * f;
        y += (p1[1] + p2[1] - 2 * first[1]) * f;
    }
    f = twicearea * 3;
    return { lng: x / f + first[0], lat: y / f + first[1] };
}

// Use an unit's English name as label text
// TODO: introduce localized name as label text
// WorkItem: https://outlookweb.visualstudio.com/MicrosoftPlaces/_workitems/edit/245464
function getLabelText(feature: MapFeature) {
    return feature.properties?.name?.en || '';
}
