import { isSuccessStatusCode } from 'owa-http-status-codes';
import { presenceServiceApi } from './presenceServiceApi';
import { convertFromLegacyMRI } from './trouter/utils';

import type { TraceErrorObject } from 'owa-trace';
import type { GetPresenceBodyType } from '../types/GetPresenceBodyType';
import type { PresenceResponseType } from '../types/PresenceResponseType';
/**
 * Fetch Presence from UPS (Unified Presence Service)
 * @param mris (MRI: Message Resource Identifier) the ids of the users that Teams understands
 * @param endpointId guid to identify a user endpoint, used for UPS telemetry purposes
 * @param correlationId guid to identify request
 * @returns
 */
export async function fetchPresence(
    mris: GetPresenceBodyType[],
    endpointId: string,
    correlationId: string
): Promise<PresenceResponseType[]> {
    // Avoid unnecessary network call
    if (mris.length == 0) {
        return [];
    }

    const response = await presenceServiceApi.makePresenceFetch(
        { endpointId, correlationId },
        mris
    );

    if (isSuccessStatusCode(response.status)) {
        const jsonVal = await response.json();
        const normalizedJsonVal = (jsonVal as PresenceResponseType[]).map(presence => {
            presence.mri = convertFromLegacyMRI(presence.mri || '');
            return presence;
        });
        return normalizedJsonVal;
    } else {
        const err: TraceErrorObject = new Error(
            'fetchPresence failed. Encounter error making service call.'
        );
        err.additionalInfo = { httpCode: response.status };
        throw err;
    }
}
