import { LazyAction, LazyModule } from 'owa-bundling';

export { default as updateRecurringFlexibleWorkHours } from './updateRecurringFlexibleWorkHours';
export { default as updateOccurringFlexibleWorkingHours } from './updateOccurringFlexibleWorkingHours';
export { useShowNotificationOnError } from './useShowNotificationOnError';
export { getWorkLocationForDay } from './getWorkLocationForDay';
export { default as getDayLocation } from './getDayLocation';
export {
    getRecurringFilteredFlexibleWorkingHours,
    getOccurringFilteredFlexibleWorkingHours,
} from './getFilteredFlexibleWorkingHours';

export { getIsPlanConfirmedForCollaborators } from './getIsPlanConfirmed';
export { setRecurringFWHInStore } from './setRecurringFWHInStore';
export { default as setOccurringFWHInStore } from './setOccurringFWHInStore';

export { getMeetingCountdown } from './getMeetingCountdown';
export { getWorkLocationLabelResourceId } from './getWorkLocationLabelResourceId';

export { default as cleanId } from './cleanId';

export { getUserWeekStartDay } from './getUserWeekStartDay';

export { default as useHybridspaceAdminLinks } from './useHybridspaceAdminLinks';
export {
    isAdminTabEnabled,
    isSpaceManagementAdminTabEnabled,
    isSpaceAnalyticAdminTabEnabled,
} from './adminUtils';
export { useDefaultAdminScreenId } from './useDefaultAdminScreenId';

export { isSpaceManagementPage } from './isSpaceManagementPage';

export { getRanking } from './getRanking';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "HybridspaceCommonUtils" */ './lazyIndex')
);
export const lazyOpenComposeEmail = new LazyAction(lazyModule, m => m.openComposeEmail);
