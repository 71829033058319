import {
    isPlacesAnalyticsHomePage,
    isPlacesHomePage,
    isPlacesPeopleTab,
} from 'hybridspace-performance-datapoints';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { type UTPageType } from 'owa-unified-telemetry';
import { isPlacesExploreEnabled } from 'places-settings';
import { isSpaceManagementPage } from './isSpaceManagementPage';

export function getCurrentPage(pathname: string): UTPageType {
    const mailboxInfo = getModuleContextMailboxInfo();
    const explorePage = isPlacesExploreEnabled(mailboxInfo);

    if (isPlacesHomePage(pathname)) {
        return explorePage ? 'Explore' : 'People';
    } else if (isPlacesPeopleTab(pathname)) {
        return 'People';
        //TODO: add workgroups path resolving here
    } else if (isSpaceManagementPage(pathname)) {
        return 'SpaceManagement';
    } else if (isPlacesAnalyticsHomePage(pathname)) {
        return 'SpaceAnalytics';
    } else {
        return 'Other';
    }
}
