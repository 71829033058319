import { collaboratorsFetchedAction } from 'hybridspace-common/lib/shared-actions';
import { createDateRange } from 'owa-datetime-utils';
import { loadAllPeopleInsights } from 'places-people-insights-operations';
import { orchestrator } from 'satcheljs';
import { getCurrentDate } from '../store';

orchestrator(collaboratorsFetchedAction, ({ collaborators, category }) => {
    if (category === 'collaborator') {
        const currentDate = getCurrentDate();
        const dateRange = createDateRange(currentDate, 2);
        loadAllPeopleInsights(dateRange, collaborators);
    }
});
