import { getUserEmailAddress } from 'accelerator-aad/lib/getUserEmailAddress';
import { type LocalStorageKeys } from 'owa-local-storage';

/**
 * The building cache key is used to store the recent buildings the user has visited
 * We use the user's email address as a prefix to the key to ensure that the cache is unique per user
 * This is to ensure that the recent buildings are not shared across users across different tenants
 */
export function getBuildingCacheKey(): LocalStorageKeys {
    const userEmail = getUserEmailAddress();
    return `${userEmail}-BUILDING_CACHE`;
}
