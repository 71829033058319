import {
    getUpsCollaboratorsFromBuildingId,
    getUpsCollaboratorsInBuildingError,
    isUpsOperationForCategoryInProgress,
} from './upsBuildingSelectors';

import type { CollaboratorSource } from 'hybridspace-common/lib/serviceTypes';

export function getPeopleInBuildingUpsFromService(category: CollaboratorSource) {
    // get the UPS data for the users in the Building
    const upsBuildingCollaboratorsDataIsLoading = isUpsOperationForCategoryInProgress(category);
    const upsBuildingCollaboratorsDataError = getUpsCollaboratorsInBuildingError(category);
    const upsBuildingCollaboratorsData = getUpsCollaboratorsFromBuildingId(category);

    const error = upsBuildingCollaboratorsDataError;

    const resultedData = upsBuildingCollaboratorsData ?? [];

    return {
        resultedData,
        loading: upsBuildingCollaboratorsDataIsLoading,
        error,
    };
}
