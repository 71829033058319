import {
    getOutOfOfficeCollaboratorsList,
    getUpsCollaboratorsInBuildingError,
    isUpsOperationForCategoryInProgress,
} from '../selectors/upsBuildingSelectors';

import type { CollaboratorSource } from 'hybridspace-common/lib/serviceTypes';
export function getPeopleOutOfOffice(category: CollaboratorSource) {
    // get the UPS data for the users OOF
    const isUpsLoading = isUpsOperationForCategoryInProgress(category);
    const upsError = getUpsCollaboratorsInBuildingError(category);
    const oofUsers = getOutOfOfficeCollaboratorsList();

    return {
        oofUsers,
        isUpsLoading,
        upsError,
    };
}
