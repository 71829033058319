import { GET_PRESENCE_ENDPOINT, SET_WORK_LOCATION_ENDPOINT } from '../urls/PresenceEndpoints';
import { getAuthToken } from '../utils/getAuthToken';
import { PRESENCE_RESOURCE, PRESENCE_RETRY_COUNT } from './constants';
import { buildRequestHeader } from './helpers/buildRequestHeader';
import { makeRequestWithRedirectHandling } from './helpers/makeRequestWithRedirectHandling';

import type { GetPresenceBodyType } from '../types/GetPresenceBodyType';
import type { SetWorkLocationRequest } from '../types/SetWorkLocationRequest';
interface IBasePresenceRequestParams {
    endpointId: string;
    correlationId: string;
}

interface IPresenceApi {
    makePresenceFetch: (
        { endpointId, correlationId }: IBasePresenceRequestParams,
        mris: GetPresenceBodyType[]
    ) => Promise<Response>;
    makeSetWorkLocationFetch: (
        { endpointId, correlationId }: IBasePresenceRequestParams,
        workLocation: SetWorkLocationRequest
    ) => Promise<Response>;
}

const PRESENCE_API_NAME = 'presence';

const makePresenceFetch = async (
    { endpointId, correlationId }: IBasePresenceRequestParams,
    mris: GetPresenceBodyType[]
) => {
    const token = await getAuthToken(PRESENCE_RESOURCE, PRESENCE_API_NAME, correlationId);
    const headers = buildRequestHeader(token, correlationId, endpointId);

    const options: RequestInit = {
        method: 'POST',
        body: JSON.stringify(mris),
        mode: 'cors',
        headers,
    };

    return makeRequestWithRedirectHandling(
        GET_PRESENCE_ENDPOINT,
        options,
        PRESENCE_RETRY_COUNT,
        (response: Response) =>
            getAuthToken(PRESENCE_RESOURCE, PRESENCE_API_NAME, correlationId, response)
    );
};

const makeSetWorkLocationFetch = async (
    { endpointId, correlationId }: IBasePresenceRequestParams,
    workLocation: SetWorkLocationRequest
): Promise<Response> => {
    const token = await getAuthToken(PRESENCE_RESOURCE, PRESENCE_API_NAME, correlationId);
    const headers = buildRequestHeader(token, correlationId, endpointId);

    const options: RequestInit = {
        method: 'PUT',
        body: JSON.stringify(workLocation),
        mode: 'cors',
        headers,
    };

    return makeRequestWithRedirectHandling(
        SET_WORK_LOCATION_ENDPOINT,
        options,
        PRESENCE_RETRY_COUNT,
        (response: Response) =>
            getAuthToken(PRESENCE_RESOURCE, PRESENCE_API_NAME, correlationId, response)
    );
};

export const presenceServiceApi: IPresenceApi = {
    makePresenceFetch,
    makeSetWorkLocationFetch,
};
