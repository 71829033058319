import { getNavigationState } from 'accelerator-router';
import { lazyHybridspaceAgendaPanelModule } from 'hybridspace-agenda-panel';
import { lazyLoadWorkspaceReservations } from 'hybridspace-desk-booking';
import { today } from 'owa-datetime';
import { createDateRange } from 'owa-datetime-utils';
import { isGuid } from 'owa-guid';
import { type UTPageType } from 'owa-unified-telemetry';
import { lazyPlacesBuildingModule } from 'places-explore';
import {
    getCurrentDate,
    getCurrentPlace,
    getDefaultCurrentPlace,
    getWorkLocationInfo,
    setRecentBuildingsFromCache,
} from 'places-explore-app-store';
import {
    loadCollaboratorsLocations,
    loadFWH,
    loadPlaces,
    loadPlacesAsync,
    loadWorkLocationInfoAsync,
} from 'places-explore-operations';
import { placesFwkPageLoadAction } from 'places-fwk-actions';
import { getPlaces, setTenantHasBuildingsCacheValue } from 'places-place-store';
import { setCurrentDate } from 'places-user-session-stores';
import { replace } from 'react-router-dom';
import { getTenantBuildingsExist } from './sharedLoaderUtils/getTenantBuildingsExist';

import type { LoaderFunction } from 'react-router-dom';
import { type HttpStatusCode } from 'owa-http-status-codes';
import { logUsage, logGreyError } from 'owa-analytics';

// Loader for the homepage route (/place)
// we only need to define a loader if the explore page feature flag is enabled
// TODO, we need to remove the explore page feature flag check once the feature is enabled for all users
export const placesHomeRouteLayoutLoader: LoaderFunction = async ({ request }) => {
    placesFwkPageLoadAction('Explore', 'start', 'Started');

    // When coming from a prefetcher (we are preloading the explore tab) we should not redirect
    // and we should not throw an error
    let shouldNotRedirect = false;
    let shouldNotThrowError = false;
    try {
        const url = new URL(request.url);
        shouldNotRedirect = url.searchParams.get('noRedirect')?.toLowerCase() === 'true';
        shouldNotThrowError = url.searchParams.get('throwError')?.toLowerCase() === 'true';
    } catch (e) {
        logUsage('PlacesHomeRouteLayoutLoaderFetchURLError', { error: e });
    }

    // Reset the Date to Today to undo any date changes from People tab.
    setCurrentDate(today());

    // Check if the tenant has places, if not return null
    setTenantHasBuildingsCacheValue();
    try {
        const tenantHasPlaces = await getTenantBuildingsExist();
        if (!tenantHasPlaces) {
            return null;
        }
    } catch (e) {
        logGreyError('placesHomeRouteLayoutLoader', e);
        // If coming from prefetcher, we will not throw an error, see PlacesRootRouteOutletContainer.tsx
        if (shouldNotThrowError) {
            return null;
        }
        throw new Response(e.message, { status: 500 });
    }

    // If the origin is a logo click, we will reset their
    // current building to the one from FWH, if it exists
    const navigationState = getNavigationState();
    const origin = navigationState?.origin;
    if (origin && origin === 'PlacesLogo') {
        const workLocationInfo = getWorkLocationInfo();
        if (workLocationInfo?.place && isGuid(workLocationInfo.place.id)) {
            return shouldNotRedirect ? null : redirectTobuildingId(workLocationInfo.place.id);
        }
    }

    const places = getPlaces();

    // Skip critical data
    // 1. If we have a default place, we will skip
    // 2. If we have places AND have checked FWH , we will skip
    let buildingId = getCurrentPlace()?.id ?? getDefaultCurrentPlace();
    const workLocationInfo = getWorkLocationInfo();
    if (buildingId || (places.size > 0 && workLocationInfo)) {
        if (buildingId) {
            return shouldNotRedirect ? null : redirectTobuildingId(buildingId);
        } else {
            return null;
        }
    }

    //We set the buildings from the cache
    setRecentBuildingsFromCache();

    /**
     * Define & Execute noncritical data: reservations, places, collaborators locations,
     * people in meetings
     */
    const todayDateRange = createDateRange(getCurrentDate(), 1);
    loadFWH(todayDateRange);

    lazyLoadWorkspaceReservations.import().then(loadWorkspaceReservations => {
        loadWorkspaceReservations(getCurrentDate());
    });

    loadPlaces();
    loadCollaboratorsLocations(todayDateRange);

    // critical data imports
    const importBuildingModulePromise = lazyPlacesBuildingModule.import();
    const importAgendaModulePromise = lazyHybridspaceAgendaPanelModule.import();

    //If we don't have a current building ID, we will try to grab it from FWH or the cache
    if (!buildingId) {
        try {
            const res = await loadWorkLocationInfoAsync(todayDateRange);
            if (res.place && isGuid(res.place.id)) {
                return shouldNotRedirect ? null : redirectTobuildingId(res.place.id);
            } else {
                // If coming from prefetcher, we will not throw an error, see PlacesRootRouteOutletContainer.tsx
                if (shouldNotThrowError) {
                    return null;
                }
                throw new Error('No place found');
            }
        } catch (e) {
            //Try to get from the cache
            buildingId = getDefaultCurrentPlace();
        }
    }

    //If we do have one, we will replace to the building page, and await the promise of critical data, in this case the lazy loaded component
    if (buildingId && isGuid(buildingId)) {
        return shouldNotRedirect ? null : redirectTobuildingId(buildingId);
    }

    // Define critical data in the case where we don't have a buildingID (Empty State)
    // places building module && and load places

    // If we have places, we don't need to load them again
    const criticalData: Promise<any>[] = [importBuildingModulePromise, importAgendaModulePromise];
    if (places.size === 0) {
        criticalData.push(loadPlacesAsync());
    }

    // If no building id is found, we will await the critical data
    try {
        await Promise.all(criticalData);
    } catch (e) {
        logGreyError('placesHomeRouteLayoutLoader', e);
        // If coming from prefetcher, we will not throw an error, see PlacesRootRouteOutletContainer.tsx
        if (shouldNotThrowError) {
            return null;
        }
        if (e.message.includes('PlacesFailed')) {
            throw new Response(e.message, { status: 500 });
        }
        throw new Response(e.message);
    }
    return null;
};

function redirectTobuildingId(buildingId: string): Response | null {
    if (!isGuid(buildingId)) {
        return null;
    }

    // Using replace instead of redirect to avoid pushing to history.state
    // else, we could get into the state of /places and /places/:buildingId in the history stack
    return replace(`/buildings/${buildingId}`);
}
