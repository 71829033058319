import { logUsage } from 'owa-analytics';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { getAccessTokenforResource } from 'owa-tokenprovider';
import { extractCommonResponseHeader } from './extractCommonResponseHeader';

export const getAuthToken = async (
    resource: string,
    apiName: 'presence' | 'idFetchFromMSGraph' | 'presenceSubscription',
    requestId: string,
    response?: Response
): Promise<string> => {
    const mailboxInfo = getModuleContextMailboxInfo();
    if (response) {
        // Response is passed only when we are retrying the request based on failed request header
        const { status, wwwAuthenticateHeader, xMsDiagnosticsHeader, cV } =
            extractCommonResponseHeader(response);
        logUsage('PresenceServiceGetAuthTokenWithResponseHeder', {
            status,
            wwwAuthenticateHeader,
            xMsDiagnosticsHeader,
            cV,
        });
    }
    const token = await getAccessTokenforResource(
        resource, // resource
        apiName, // apiName
        requestId, // requestId
        undefined, // targetTenantId
        response?.headers?.get('WWW-Authenticate') || '', // wwwAuthenticateHeader
        false, // preferIdpToken
        mailboxInfo // mailboxInfo
    );

    // TODO: to handle when we face this. currently we're handling it by downstream service call fails and we do a retry
    if (!token) {
        throw new Error('Presence: No access token for presence service');
    }

    return token;
};
