import {
    getUpsCollaboratorsBuildingLocationsLastError,
    loadUpsCollaboratorsBuildingLocationsInProgress,
} from 'places-ups-location-operations';
import {
    getCollaboratorsInMeetingsStatus,
    getCollaboratorsInOfficeStatus,
    getCollaboratorsOutOfficeStatus,
    getLastServicePeopleInMeetingsSuccessfullyCompleted,
    getLastServicePeopleInOfficeSuccessfullyCompleted,
} from '../store';

import type { CollaboratorSource } from 'hybridspace-common/lib/serviceTypes';

export function getUpsCollaboratorsInBuildingLoading(category: CollaboratorSource) {
    return loadUpsCollaboratorsBuildingLocationsInProgress(category as string);
}

export function getUpsCollaboratorsInBuildingError(category: CollaboratorSource) {
    return getUpsCollaboratorsBuildingLocationsLastError(category as string);
}

export function getUpsCollaboratorsFromBuildingId(category: CollaboratorSource) {
    return category === 'collaborator'
        ? getCollaboratorsInOfficeStatus()
        : getCollaboratorsInMeetingsStatus();
}

export function getOutOfOfficeCollaboratorsList() {
    return getCollaboratorsOutOfficeStatus();
}

export function getUpsLastServiceSuccessfullyCompleted(category: CollaboratorSource) {
    return category === 'collaborator'
        ? getLastServicePeopleInOfficeSuccessfullyCompleted()
        : getLastServicePeopleInMeetingsSuccessfullyCompleted();
}

export function isUpsOperationForCategoryInProgress(category: CollaboratorSource) {
    return (
        (getUpsCollaboratorsInBuildingLoading(category) ||
            getUpsCollaboratorsFromBuildingId(category) == null) && // if the operation is in progress or has not started yet(=== null)
        !getUpsLastServiceSuccessfullyCompleted(category)
    );
}
