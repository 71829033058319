import { fetchMRIsFromMsGraph } from 'accelerator-presence-service/lib/mris';
import { fetchPresence } from 'accelerator-presence-service/lib/presence';
import { DatapointStatus, logGreyError, PerformanceDatapoint } from 'owa-analytics';
import { getSessionId } from 'owa-config';
import { getGuid } from 'owa-guid';

import type { UPSCollaborator } from '../types/UPSCollaborator';
import type {
    GetPresenceBodyType,
    PresenceIdentifier,
} from 'accelerator-presence-service/lib/types';

export const STEP_TO_WATERFALL_INDEX_MAP = {
    fetchMrisCompleted: 1,
} as const;

export default async function getUpsLocationForCollaboratorsInBuildingToday(
    collaboratorsEmails: string[]
): Promise<UPSCollaborator[]> {
    if (collaboratorsEmails.length == 0) {
        return [];
    }
    const endpointGUID = getSessionId();
    const correlationId = getGuid();

    const lowerCaseCollaboratorsEmails = collaboratorsEmails.map(email => email.toLowerCase());
    const userUPSInformationMap = new Map<string, UPSCollaborator>();
    const perfDatapoint = new PerformanceDatapoint('PlacesGetUpsLocationForCollaborators');
    perfDatapoint.addCustomData({
        emailCount: collaboratorsEmails.length,
        correlationId,
    });
    try {
        const collaboratorMRIList: PresenceIdentifier[] = [];
        const collaboratorsMRIs = await fetchMRIsFromMsGraph(
            lowerCaseCollaboratorsEmails,
            true /*Use cache*/
        );

        const collaboratorsMRIsCount = collaboratorsMRIs.length;

        if (collaboratorsMRIsCount !== lowerCaseCollaboratorsEmails.length) {
            perfDatapoint.addCustomData({
                mriMismatch: `mriCount: ${collaboratorsMRIsCount} emailCount: ${lowerCaseCollaboratorsEmails.length}`,
            });
        }

        perfDatapoint.addToCustomWaterfall(
            STEP_TO_WATERFALL_INDEX_MAP['fetchMrisCompleted'],
            'fetchMrisCompleted',
            true /** discard if defined */
        );

        if (collaboratorsMRIsCount > 0) {
            collaboratorsMRIs.forEach(presenceIdentifier => {
                const userSMTP = presenceIdentifier?.smtp;
                if (userSMTP) {
                    collaboratorMRIList.push(presenceIdentifier);
                }
            });

            // create the body entries and initialize the userUPSInformationMap
            const collaboratorsBodyEntries: GetPresenceBodyType[] = [];
            collaboratorMRIList.forEach(presenceIdentifier => {
                const userMRI = presenceIdentifier?.mri;
                if (userMRI) {
                    userUPSInformationMap.set(userMRI, {
                        primarySmtpAddress: presenceIdentifier?.smtp,
                        mailboxId: userMRI.split(':')[2] || '',
                        isCheckedIn: false,
                        buildingId: undefined,
                        isOutOfOffice: false,
                    });
                    collaboratorsBodyEntries.push({
                        mri: userMRI,
                        source: 'ups',
                        etag: undefined,
                    } as GetPresenceBodyType);
                }
            });

            // call for the presence of the collaborators
            const collaboratorsPresence = await fetchPresence(
                collaboratorsBodyEntries,
                endpointGUID,
                correlationId
            );

            // assign the results to the UpsCollaboratorsInformation
            collaboratorsPresence.forEach(collaboratorPresence => {
                const presenceIdentifier = collaboratorPresence?.mri;
                const userUPS =
                    presenceIdentifier !== undefined
                        ? userUPSInformationMap.get(presenceIdentifier)
                        : null;

                if (userUPS && presenceIdentifier && collaboratorPresence?.presence) {
                    const source = collaboratorPresence.presence?.workLocation?.locationSource;
                    userUPS.isCheckedIn =
                        source == 'ForcedLocation' || source == 'VerifiedLocation';
                    userUPS.buildingId =
                        collaboratorPresence.presence?.workLocation?.approximateDetails?.id;
                    userUPS.isOutOfOffice =
                        collaboratorPresence.presence?.calendarData?.isOutOfOffice;
                    userUPSInformationMap.set(presenceIdentifier, userUPS);
                }
            });
        }

        perfDatapoint.end();
        return Array.from(userUPSInformationMap.values());
    } catch (error) {
        logGreyError('PlacesGetUpsLocationForCollaboratorsError', error);
        perfDatapoint.endWithError(DatapointStatus.ClientError, error);
        throw new Error('Error loading the UPS location for collaborators today');
    }
}
