import { orchestrator } from 'satcheljs';

import { loadAllPeopleInsightsSucceeded } from 'places-people-insights-operations';
import { addRareVisitData } from '../store';

orchestrator(loadAllPeopleInsightsSucceeded, ({ result }) => {
    // Loop through the Map of <collaborator, array of buildingIds>
    result.forEach((buildingIds, collaborator) => {
        // Call addRareVisitData for each collaborator with its unique list of buildingIds
        addRareVisitData(collaborator, buildingIds);
    });
});
