import { getRareVisitData } from '../store';
import type { UserEmailAddress } from 'accelerator-aad/lib/UserEmailAddress';
import { isRareVisit } from './isRareVisit';

export function getInsightCountInBuilding(emailAddresses: UserEmailAddress[]): number {
    const rareVisitData = getRareVisitData();
    if (rareVisitData.size === 0) {
        // if there is no data for that collaborator, then we cannot determine rare visit yet
        return 0;
    }

    let insightCount = 0;

    emailAddresses.map(email => {
        insightCount += isRareVisit(email) ? 1 : 0;
    });

    return insightCount;
}
