import getNoCharmInfoObject from 'owa-calendar-charms-store/lib/utils/getNoCharmInfoObject';
import type {
    default as CalendarEvent,
    CalendarItemTypeTypeEx,
} from 'owa-calendar-types/lib/types/CalendarEvent';
import getDefaultReminder from './getDefaultReminder';
import initializeCalendarFolderId from './initializeCalendarFolderId';
import type { OwaDate } from 'owa-datetime';
import {
    addHours,
    addMinutes,
    now,
    startOfHour,
    startOfDay,
    addDays,
    differenceInMinutes,
} from 'owa-datetime';
import type { ClientFolderId } from 'owa-client-ids';
import type { MailboxInfo } from 'owa-client-types';
import { getConfiguredEventStartTimeEndTime } from 'owa-calendar-cloud-settings-utils';
import getDefaultEventDuration from 'owa-session-store/lib/selectors/getDefaultEventDuration';
import type { OwaDateRange } from 'owa-datetime-utils/lib/OwaDateRange';
import { getTimeScaleSetting } from 'owa-calendar-surface-options-utils';
import { getCoprincipalMailboxInfoForMailboxInfo } from 'owa-account-source-list-store';
import { getDefaultEventSetReminder } from 'owa-calendar-cloud-settings';
import { DefaultReminderOffsets } from 'owa-calendar-reminder-options/lib/utils/emailReminderConsts';

const DEFAULT_SHORTENED_MINIMUM_DURATION = 15;

/**
 * Initializes a full CalendarEvent when given a partial or null calendar event
 * @param data Optional partial calendar event
 * @param isInitializedEvent Optional - set to true for existing/initialized events (e.g. edit appointment in quick compose)
 * @param shouldRespectStartEndTime Indicates if the start time and end time should be respected (no speedy meetings applied)
 */
export default function initializeCalendarEvent(
    data?: Partial<CalendarEvent>,
    isInitializedEvent?: boolean,
    shouldRespectStartEndTime?: boolean,
    useTimeScaleSetting?: boolean,
    mailboxInfo?: MailboxInfo
): CalendarEvent {
    // Create default dates
    let startDate: OwaDate;
    let endDate: OwaDate;
    const eventDuration: number = useTimeScaleSetting
        ? getTimeScaleSetting()
        : getDefaultEventDuration();
    let defaultReminderMinutes: number;
    let defaultReminderIsSet: boolean;
    let parentFolderId: ClientFolderId;
    let isOnlineMeeting: boolean = false;
    let shouldAdjustDateRange: boolean = !isInitializedEvent && !shouldRespectStartEndTime;

    if (!data) {
        // If we dont have data, we need to initialize the start and the end dates
        startDate = addHours(startOfHour(now()), 1);
        endDate = addMinutes(startDate, eventDuration);

        parentFolderId = initializeCalendarFolderId(mailboxInfo);
        defaultReminderMinutes = getDefaultReminder(
            getCoprincipalMailboxInfoForMailboxInfo(parentFolderId.mailboxInfo) ??
                parentFolderId.mailboxInfo,
            false /* isAllDayEvent */
        );
        defaultReminderIsSet = getDefaultEventSetReminder(
            getCoprincipalMailboxInfoForMailboxInfo(parentFolderId.mailboxInfo) ??
                parentFolderId.mailboxInfo
        );
    } else {
        if (data.IsAllDayEvent) {
            startDate = data.Start || startOfDay(now());
            endDate = data.End || startOfDay(addDays(startDate, 1));
            shouldAdjustDateRange = false;
        } else {
            startDate = data.Start || addHours(startOfHour(now()), 1);
            endDate = data.End || addMinutes(startDate, eventDuration);
        }
        parentFolderId = data.ParentFolderId ?? initializeCalendarFolderId(mailboxInfo);

        defaultReminderMinutes =
            data.ReminderMinutesBeforeStart ||
            getDefaultReminder(parentFolderId.mailboxInfo, data.IsAllDayEvent);

        defaultReminderIsSet =
            data.ReminderMinutesBeforeStart != null
                ? defaultReminderMinutes != DefaultReminderOffsets.NO_REMINDER
                : getDefaultEventSetReminder(parentFolderId.mailboxInfo);

        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (63,9): Type 'boolean | undefined' is not assignable to type 'boolean'.
        // @ts-expect-error
        isOnlineMeeting = data.IsOnlineMeeting;
    }

    if (shouldAdjustDateRange) {
        // Adjust startDate and endDate based on the shorten event duration setting
        // Makes sure the adjustment is made only once - when the event is initialized for the first time
        const adjustedDateRange: OwaDateRange = getConfiguredEventStartTimeEndTime(
            startDate,
            endDate,
            parentFolderId.mailboxInfo
        );

        const duration = differenceInMinutes(adjustedDateRange.end, adjustedDateRange.start);
        if (
            !useTimeScaleSetting ||
            (useTimeScaleSetting && duration >= DEFAULT_SHORTENED_MINIMUM_DURATION)
        ) {
            startDate = adjustedDateRange.start;
            endDate = adjustedDateRange.end;
        }
    }

    // Setup default event
    let event: CalendarEvent = {
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (82,9): Type 'null' is not assignable to type 'ClientItemId'.
        // @ts-expect-error
        ItemId: null,
        ParentFolderId: parentFolderId,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (87,9): Type 'null' is not assignable to type 'string'.
        // @ts-expect-error
        Subject: null,
        ReminderIsSet: defaultReminderIsSet,
        ReminderMinutesBeforeStart: defaultReminderIsSet ? defaultReminderMinutes : undefined,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (93,9): Type 'null' is not assignable to type 'OwaDate'.
        // @ts-expect-error
        LastModifiedTime: null,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (97,9): Type 'null' is not assignable to type 'string'.
        // @ts-expect-error
        InstanceKey: null,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (101,9): Type 'null' is not assignable to type 'string'.
        // @ts-expect-error
        Preview: null,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (105,9): Type 'null' is not assignable to type 'string'.
        // @ts-expect-error
        __type: null,
        Start: startDate,
        StartTimeZoneId: startDate.tz,
        End: endDate,
        EndTimeZoneId: endDate.tz,
        IsAllDayEvent: false,
        IsRoomRequested: false,
        IsDraft: false,
        FreeBusyType: 'Busy',
        IsMeeting: false,
        IsCancelled: false,
        IsRecurring: false,
        IsResponseRequested: true, // to be coherent with jsmvvm
        CalendarItemType: 'Single' as CalendarItemTypeTypeEx,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (126,9): Type 'null' is not assignable to type 'ResponseType'.
        // @ts-expect-error
        ResponseType: null,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (130,9): Type 'null' is not assignable to type 'SingleRecipientType'.
        // @ts-expect-error
        Organizer: null,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (134,9): Type 'null' is not assignable to type 'AttendeeType[]'.
        // @ts-expect-error
        RequiredAttendees: null,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (138,9): Type 'null' is not assignable to type 'AttendeeType[]'.
        // @ts-expect-error
        OptionalAttendees: null,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (142,9): Type 'null' is not assignable to type 'AttendeeType[]'.
        // @ts-expect-error
        Resources: null,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (146,9): Type 'null' is not assignable to type 'OwaDate'.
        // @ts-expect-error
        AppointmentReplyTime: null,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (150,9): Type 'null' is not assignable to type 'RecurrenceType'.
        // @ts-expect-error
        Recurrence: null,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (154,9): Type 'null' is not assignable to type 'EnhancedLocation'.
        // @ts-expect-error
        Location: null,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (158,9): Type 'null' is not assignable to type 'BusyType'.
        // @ts-expect-error
        IntendedFreeBusyStatus: null,
        IsOrganizer: true,

        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (167,9): Type 'null' is not assignable to type 'boolean'.
        // @ts-expect-error
        IsCoOrganizer: null,
        IsPresenter: false,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (163,9): Type 'null' is not assignable to type 'string'.
        // @ts-expect-error
        AppointmentReplyName: null,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (167,9): Type 'null' is not assignable to type 'boolean'.
        // @ts-expect-error
        IsSeriesCancelled: null,
        CharmId: getNoCharmInfoObject().IconId,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (172,9): Type 'null' is not assignable to type 'EnhancedLocation[]'.
        // @ts-expect-error
        Locations: null,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (176,9): Type 'null' is not assignable to type 'string'.
        // @ts-expect-error
        ClientSeriesId: null,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (180,9): Type 'null' is not assignable to type 'EffectiveRightsType'.
        // @ts-expect-error
        EffectiveRights: null,
        HasAttachments: false,
        HasBlockedImages: false,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (186,9): Type 'null' is not assignable to type 'AttachmentTypeUnion[]'.
        // @ts-expect-error
        Attachments: null,
        Sensitivity: 'Normal',
        Body: {
            Value: '',
            BodyType: 'HTML',
            QuotedText: undefined,
            IsTruncated: undefined,
            UTF8BodySize: undefined,
            BodyFragmentInformation: undefined,
            DataUriCount: undefined,
            CustomDataUriCount: undefined,
        }, // Initialize with empty HTML value since we want the body to be HTML bodytype so that attachments don't fail and online meeting blob gets properly HTML formatted
        EntityNamesMap: 0,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (196,9): Type 'null' is not assignable to type 'string'.
        // @ts-expect-error
        TailoredXpEntities: null,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (200,9): Type 'null' is not assignable to type 'string'.
        // @ts-expect-error
        UID: null,
        DoNotForwardMeeting: false,
        IsOnlineMeeting: isOnlineMeeting,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (211,9): Type 'null' is not assignable to type 'string[]'.
        // @ts-expect-error
        Categories: null,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (215,9): Type 'null' is not assignable to type 'InboxReminder[]'.
        // @ts-expect-error
        InboxReminders: null,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (219,9): Type 'null' is not assignable to type 'boolean'.
        // @ts-expect-error
        MeetingRequestWasSent: null,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (223,9): Type 'null' is not assignable to type 'ItemId'.
        // @ts-expect-error
        ExtractionSourceId: null,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (227,9): Type 'null' is not assignable to type 'string'.
        // @ts-expect-error
        OnlineMeetingProvider: null,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (231,9): Type 'null' is not assignable to type 'string'.
        // @ts-expect-error
        OnlineMeetingJoinUrl: null,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (235,9): Type 'null' is not assignable to type 'string'.
        // @ts-expect-error
        OnlineMeetingConferenceId: null,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (239,9): Type 'null' is not assignable to type 'string'.
        // @ts-expect-error
        OnlineMeetingTollNumber: null,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (243,9): Type 'null' is not assignable to type 'string[]'.
        // @ts-expect-error
        OnlineMeetingTollFreeNumbers: null,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (247,9): Type 'null' is not assignable to type 'ClientItemId'.
        // @ts-expect-error
        SeriesMasterItemId: null,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (251,9): Type 'null' is not assignable to type 'string'.
        // @ts-expect-error
        ExtractionSourceInternetMessageId: null,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (255,9): Type 'null' is not assignable to type 'ItemId'.
        // @ts-expect-error
        PersonId: null,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (259,9): Type 'null' is not assignable to type 'string'.
        // @ts-expect-error
        InReplyTo: null,
        HideAttendees: false,
        ResponseMode: 0,
        Attendance: 0,
        MuteNotifications: false,
        RightsManagementLicenseData: undefined,
        CalendarEventClassifications: [],
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (264,9): Type 'null' is not assignable to type 'boolean | undefined'.
        // @ts-expect-error
        IsFreeBusyOnly: null,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (268,9): Type 'null' is not assignable to type 'SkypeTeamsPropertiesData'.
        // @ts-expect-error
        SkypeTeamsProperties: null,
        DocLinks: [],
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (273,9): Type 'null' is not assignable to type 'string'.
        // @ts-expect-error
        DateTimeCreated: null,
        AppendOnSend: [],
        PrependOnSend: [],
        ConflictResolution: undefined,
        IsBookedFreeBlocks: false,
        AssociatedTasks: [],
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (280,9): Type 'null' is not assignable to type 'CollabSpaceData | undefined'.
        // @ts-expect-error
        CollabSpace: null,
        // @ts-expect-error
        ReferenceItemId: null,
        // @ts-expect-error
        ClpLabelProperty: null,
        // @ts-expect-error
        ClpLabelModificationHeader: null,
        AllowNewTimeProposal: undefined,
        RequestedAttendanceMode: 'Default',
        OnlineMeeting: undefined,
        IsFollowableMeeting: false,
        AttendanceSource: undefined,
    };

    // Override default with provided data
    if (data) {
        event = {
            ...event,
            ...data,
            // Re-apply calculated values to avoid being overwritten
            ...{ Start: startDate, End: endDate, ParentFolderId: parentFolderId },
        };

        if (!data.FreeBusyType) {
            event.FreeBusyType = data.IsAllDayEvent ? 'Free' : 'Busy';
        }
    }

    return event;
}
