import { logPlacesEvent } from 'hybridspace-telemetry';
import mobile from 'is-mobile';
import microsoftPlacesLogo from 'owa-appbar-icons/lib/microsoftPlaces.svg';
import { isRunningInMetaOSHub } from 'owa-config';
import { observer } from 'owa-mobx-react';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { type UTPageType } from 'owa-unified-telemetry';
import { PLACES_BRANDING_NAME } from 'places-common-strings';
import { isPlacesExploreEnabled } from 'places-settings';
import React from 'react';
import { useNavigate } from 'react-router';
import { Link, Subtitle1, Subtitle2 } from '@fluentui/react-components';
import { logo, logoIcon, logoIconMetaOS } from './HybridspaceLogo.scss';

export default observer(function HybridspaceLogo({
    sourcePage,
}: {
    sourcePage: UTPageType | undefined;
}) {
    const title = PLACES_BRANDING_NAME;
    const mailboxInfo = getModuleContextMailboxInfo();
    const explorePage = isPlacesExploreEnabled(mailboxInfo);
    const id = 'PlacesLogo';
    const navigate = useNavigate();

    const onClick = React.useCallback(() => {
        navigate('/', { state: { origin: id } });
        const destinationPage = explorePage ? 'Explore' : 'People';
        if (sourcePage && sourcePage != destinationPage) {
            logPlacesEvent({
                eventName: 'PlacesNavigationClick',
                data: {
                    SourcePage: sourcePage,
                    DestinationPage: destinationPage,
                },
            });
        }
    }, [navigate, explorePage, sourcePage]);

    const isRunningInMetaOS = isRunningInMetaOSHub();
    const isMobile = mobile();

    return (
        <Link className={logo} data-telemetry-id={id} onClick={onClick} appearance="subtle">
            <img
                className={isRunningInMetaOS ? logoIconMetaOS : logoIcon}
                src={microsoftPlacesLogo}
                aria-label={title}
            />
            {isRunningInMetaOS ? (
                !isMobile && <Subtitle2>{title}</Subtitle2>
            ) : (
                <Subtitle1>{title}</Subtitle1>
            )}
        </Link>
    );
},
'HybridspaceLogo');
