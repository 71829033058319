import { initCalendarMailboxConfiguration } from 'owa-calendar-configuration';
import { loadPlacesSucceeded } from 'places-explore-operations';
import { setShowBuildingFilterOptions } from 'places-user-session-stores';
import { orchestrator } from 'satcheljs';
import { setPlace, setTenantHasBuildingsStoreAndCache, addServiceCallsCompleted } from '../store';

import type { Place } from 'owa-graph-schema';

orchestrator(loadPlacesSucceeded, ({ result }) => {
    if (result.size === 0) {
        setTenantHasBuildingsStoreAndCache(false);
        setShowBuildingFilterOptions(false);
    } else {
        setTenantHasBuildingsStoreAndCache(true);
        setShowBuildingFilterOptions(true);
    }

    const placesArray = Array.from(result);
    const sortedPlaces = [...placesArray].sort(comparePlaces);
    sortedPlaces.forEach(([key, value]) => {
        setPlace(key, value);
    });

    // Call initCalendarMailboxConfiguration to get temperature unit to infer distance unit
    initCalendarMailboxConfiguration();

    addServiceCallsCompleted('all', true);
});

function comparePlaces(a: [string, Place], b: [string, Place]) {
    if (a[1].name && b[1].name) {
        return a[1].name.localeCompare(b[1].name, undefined /**locales */, {
            numeric: true,
            sensitivity: 'base',
        });
    }
    return 0; // Can't sort.
}
