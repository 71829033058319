import type { LoaderFunction } from 'react-router-dom';
import { areAllWorkGroupsLoaded, loadAllWorkGroupsAsync } from 'places-work-groups';
import { logGreyError, logUsage } from 'owa-analytics';

export const AllWorkGroupsLoader: LoaderFunction = async ({ request }) => {
    // When coming from a prefetcher (we are preloading the people tab) we should not throw an error
    let shouldNotThrowError = false;
    try {
        const url = new URL(request.url);
        shouldNotThrowError = url.searchParams.get('throwError')?.toLowerCase() === 'true';
    } catch (e) {
        logUsage('PlacesAllWorkGroupsLoaderFetchURLError', { error: e });
    }

    if (!areAllWorkGroupsLoaded()) {
        try {
            await loadAllWorkGroupsAsync();
        } catch (error) {
            logGreyError('AllWorkGroupsLoader', error);
            // If coming from prefetcher, we will not throw an error, see PlacesRootRouteOutletContainer.tsx
            if (!shouldNotThrowError) {
                return null;
            }
            throw error;
        }
    }
    return null;
};
