import { DatapointStatus, PerformanceDatapoint } from 'owa-analytics';
import { MILLISECONDS_IN_MINUTE } from 'owa-date-constants';
import React from 'react';
import markPlacesFinderPerfCheckpoint from '../utils/markPlacesFinderPerfCheckpoint';

import type {
    IUTEPlacesFinderLoadE2EArgs,
    UTPlaceReservationOriginType,
} from 'owa-unified-telemetry';
import type { PlaceType } from 'hybridspace-common/lib/constants';
// Creates a performance datapoint for PlacesFinder load. The datapoint exists while the Places Finder
// container is opened and is ended when the container is dismpunted.
export function usePlacesFinderPerformanceDatapoint(
    placesType: PlaceType.Room | PlaceType.DeskPool,
    openPlacesFinderTimestamp: number,
    entryPoint: UTPlaceReservationOriginType
): PerformanceDatapoint {
    /* eslint-disable-next-line owa-custom-rules/prefer-react-state-without-arrays-or-objects -- (https://aka.ms/OWALintWiki)
     * Please remove the array or object from React.useState() or leave a justification in case is not possible to do so.
     *	> It is preferable not to use arrays or objects as react state, use primitive data types, useReducer or satchel state instead, if its possible. */
    const [perfDatapoint] = React.useState<PerformanceDatapoint>(() => {
        const datapoint = new PerformanceDatapoint('GetPlaceSuggestionsUserLatency', {
            timeout: MILLISECONDS_IN_MINUTE * 2,
            customStartTime: openPlacesFinderTimestamp,
        });
        datapoint.addUnifiedTelemetryData({
            eventName: 'PlacesFinderLoadE2E',
            data: {} as IUTEPlacesFinderLoadE2EArgs /** PerfProperties and PerfCustomWaterfall is handled in logUnifiedDatapoint & mergeEvents */,
        });

        datapoint.addCustomData({
            Origin: entryPoint,
            PlacesType: placesType,
        });

        markPlacesFinderPerfCheckpoint(datapoint, 'PlacesFinderInternalLoadStart');

        return datapoint;
    });

    React.useEffect(() => {
        return () => {
            // Clean up after unmount
            if (!perfDatapoint.hasEnded) {
                perfDatapoint.end(undefined, DatapointStatus.UserCancelled);
            }
        };
    }, [perfDatapoint]);

    return perfDatapoint;
}
