import { fetch } from 'owa-fetch';
import { logGreyError } from 'owa-analytics';
import { isFeatureEnabled } from 'owa-feature-flags';
import isRetriableAuthError from 'owa-service/lib/isRetriableAuthError';
import { buildAuthorizationHeader } from 'owa-tokenprovider-utils';
import { getUPSBaseUrl, setUPSBaseUrl } from '../../urls/UPSBaseUrl';

import type { ApiEndpoints } from '../../urls/PresenceEndpoints';

const MAX_RETRY_COUNT = 1;

export const makeRequestWithRedirectHandling = async (
    apiEndpoint: ApiEndpoints,
    options: RequestInit,
    retryCount: number = MAX_RETRY_COUNT,
    getAuthToken?: (response: Response) => Promise<string>
): Promise<Response> => {
    const url = buildUrl(apiEndpoint);
    return fetch(url, options)
        .then(async response => {
            // HTTP Status Codes from UPS https://test.presence.services.sfb.trafficmanager.net/swagger/ui/index#!/Presence/Presence_GetPresenceByMri
            // 301 - Redirect user to another region
            // 400 - Mri batch size is invalid
            // 401 - Invalid user region (we also get this if token is invalid)
            // 404 - User not found - or override behavior for server redirect

            // Handle 404 when location is set (which means it is overriding 301 server redirect)
            // by using the full redirect location (including params) to fetch again
            // but then caching only the host for future requests (without params)
            if (response.status == 404) {
                const redirectLocation: string | null = response.headers.get('location');
                if (redirectLocation) {
                    const baseUrl = redirectLocation.substring(
                        0,
                        redirectLocation.indexOf(apiEndpoint)
                    );
                    setUPSBaseUrl(baseUrl);
                    return makeRequestWithRedirectHandling(apiEndpoint, options);
                }
            }

            /**
             * in case it's a retriable auth error, we will retry the request with a new token
             */
            if (
                isFeatureEnabled('msplaces-presenceservice-authretry') &&
                isRetriableAuthError(response.status, true /** authNeededOnUnAuthorized */) &&
                retryCount > 0 &&
                getAuthToken
            ) {
                // Passing response will internally checks for auth CAE issues to retry.
                const authToken = await getAuthToken(response);
                const newOption = {
                    ...options,
                    headers: {
                        ...options.headers,
                        Authorization: buildAuthorizationHeader(authToken),
                    },
                };
                return makeRequestWithRedirectHandling(
                    apiEndpoint,
                    newOption,
                    retryCount - 1,
                    getAuthToken
                );
            }
            return response;
        })
        .catch(error => {
            logGreyError('PresenceService_FetchError', error, {
                apiEndpoint,
                retryCount,
                correlationId: error?.response?.headers?.get('x-ms-correlation-id'),
                cV: error?.response?.headers?.get('ms-cv'),
            });
            throw error;
        });
};

const buildUrl = (endpoint: ApiEndpoints): string => {
    return getUPSBaseUrl() + endpoint;
};
