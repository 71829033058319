import type { DateRange } from 'owa-datetime-utils';
import { getUserExternalDirectoryUserGuid } from 'accelerator-aad/lib/getUserExternalDirectoryUserGuid';
import { fetchPresence } from 'accelerator-presence-service/lib/presence';
import { DatapointStatus, PerformanceDatapoint } from 'owa-analytics';
import { getGuid } from 'owa-guid';
import { getDevErrorPageState } from 'places-diagnostics';
import {
    getCurrentSegmentLocation,
    getPlace,
    getPlaceFromWorkspace,
    getPlacesEmployeeFWH,
} from 'places-service';

import type {
    GetPresenceBodyType,
    PresenceResponseType,
} from 'accelerator-presence-service/lib/types';

import type { WorkLocationInfo } from '../operationTypes';

export async function getPlacesWorkLocationInfo(
    currentCalendarDateRangeView: DateRange,
    isRetry?: boolean
): Promise<WorkLocationInfo> {
    const sessionId = getGuid();
    let workInfo = {
        relativeLocation: 'Unspecified',
        workspaceId: undefined,
        workspaceName: undefined,
        place: undefined,
        isPlaceCheckedIn: false,
        checkedInPlace: undefined,
        errorLoadingPresence: false,
        fwh: [],
    } as WorkLocationInfo;

    const perfDatapoint = new PerformanceDatapoint('PlacesGetWorkLocationInfo');

    try {
        const [employeeFWHResponse, collaboratorsPresenceResponse] = await Promise.allSettled([
            getPlacesEmployeeFWH(currentCalendarDateRangeView),
            fetchPresence(
                [
                    {
                        mri: `8:orgid:${getUserExternalDirectoryUserGuid()}`,
                        source: 'ups',
                        etag: undefined,
                    } as GetPresenceBodyType,
                ],
                'PlacesExplore',
                sessionId
            ),
        ]);

        const employeeFWHIsSuccessful = employeeFWHResponse.status === 'fulfilled';
        const collaboratorsPresenceIsSuccessful =
            collaboratorsPresenceResponse.status === 'fulfilled';

        if (employeeFWHIsSuccessful && collaboratorsPresenceIsSuccessful) {
            perfDatapoint.end();
        } else {
            perfDatapoint.addCustomData({
                correlationId: sessionId,
                reason: `employeeFWHResponse: ${employeeFWHResponse.status} collaboratorsPresenceResponse: ${collaboratorsPresenceResponse.status}`,
            });
            perfDatapoint.endWithError(DatapointStatus.ServerError);
        }

        const employeeFWH = employeeFWHIsSuccessful ? employeeFWHResponse.value : [];
        const collaboratorsPresence =
            collaboratorsPresenceIsSuccessful && 'value' in collaboratorsPresenceResponse
                ? (collaboratorsPresenceResponse.value as PresenceResponseType[])
                : ([] as PresenceResponseType[]);

        const currentSegment = getCurrentSegmentLocation(
            employeeFWH,
            currentCalendarDateRangeView.start
        );

        // Get the current presence of the user
        const userPresence = collaboratorsPresence?.[0]?.presence;
        const userUpsWorkLocation = userPresence?.workLocation?.location;
        const userUpsLocationId = userPresence?.workLocation?.approximateDetails?.id;
        const userUpsLocationName = userPresence?.workLocation?.approximateDetails?.name;

        const userWorkLocation = userUpsWorkLocation ?? currentSegment.workLocation;
        const userLocationSource = userPresence?.workLocation?.locationSource;
        const isCheckedIn =
            (userLocationSource === 'ForcedLocation' ||
                userLocationSource === 'VerifiedLocation') &&
            userUpsWorkLocation === 'Office';

        // We set the default work location info to return, if work location is empty we set unspecified
        workInfo = {
            relativeLocation: userWorkLocation ?? 'Unspecified',
            workspaceId: undefined,
            workspaceName: undefined,
            place: undefined,
            isPlaceCheckedIn: false,
            checkedInPlace: undefined,
            errorLoadingPresence:
                !collaboratorsPresenceIsSuccessful ||
                (!isRetry && getDevErrorPageState() === 'ErrorLoadingUpsLocation'),
            fwh: employeeFWH,
        } as WorkLocationInfo;

        // check if UPS information is available and update the check in place
        if (isCheckedIn) {
            const hasWorkspace = currentSegment.locationDetails?.locationType === 'WorkSpace';
            try {
                const place = userUpsLocationId ? await getPlace(userUpsLocationId) : undefined;
                workInfo = {
                    ...workInfo,
                    // update the UPS information only
                    place,
                    workspaceId: hasWorkspace ? currentSegment.locationDetails?.id : undefined,
                    workspaceName: hasWorkspace
                        ? currentSegment.locationDetails?.displayName
                        : undefined,
                    isPlaceCheckedIn: isCheckedIn,
                    checkedInPlace:
                        isCheckedIn && userUpsLocationId
                            ? {
                                  id: userUpsLocationId,
                                  name: userUpsLocationName,
                              }
                            : undefined,
                } as WorkLocationInfo;
            } catch (error) {
                // if we fail to get place info, we fall back to constructing our own place
                workInfo = {
                    ...workInfo,
                    workspaceId: hasWorkspace ? currentSegment.locationDetails?.id : undefined,
                    workspaceName: hasWorkspace
                        ? currentSegment.locationDetails?.displayName
                        : undefined,
                    place: {
                        id: userUpsLocationId,
                        name: userUpsLocationName,
                        type: 'building',
                    },
                    isPlaceCheckedIn: isCheckedIn,
                    checkedInPlace: isCheckedIn
                        ? {
                              id: userUpsLocationId,
                              name: userUpsLocationName,
                          }
                        : undefined,
                } as WorkLocationInfo;
            }
        } else if (
            /**
             * For getSchedule, check that the current location is office
             *  */
            currentSegment.workLocation === 'Office' &&
            currentSegment.locationDetails &&
            currentSegment.locationDetails.locationType
        ) {
            // If current location is building, we try to get the Place through the ID,
            if (currentSegment.locationDetails.locationType == 'Building') {
                try {
                    const buildingId = currentSegment.locationDetails?.id;
                    if (buildingId) {
                        const place = await getPlace(buildingId);
                        workInfo = {
                            ...workInfo,
                            workspaceId: undefined,
                            workspaceName: undefined,
                            place,
                        } as WorkLocationInfo;
                    } else {
                        throw new Error('No building Id');
                    }
                } catch (error) {
                    // if we fail to get place info, we fall back to constructing our own place
                    workInfo = {
                        ...workInfo,
                        workspaceId: undefined,
                        workspaceName: undefined,
                        place:
                            currentSegment.locationDetails?.id &&
                            currentSegment.locationDetails?.displayName
                                ? {
                                      id: currentSegment.locationDetails?.id,
                                      name:
                                          currentSegment.locationDetails?.displayName ?? undefined,
                                      type: 'building',
                                  }
                                : undefined,
                    } as WorkLocationInfo;
                }
            }
            // If current location is a workspace, set workspace details, and fetch Place info
            else if (
                currentSegment.locationDetails.locationType == 'WorkSpace' &&
                currentSegment.locationDetails?.id
            ) {
                // if workspace id exist, try to get building info
                try {
                    const place = await getPlaceFromWorkspace(currentSegment.locationDetails.id);
                    workInfo = {
                        ...workInfo,
                        workspaceId: currentSegment.locationDetails?.id,
                        workspaceName: currentSegment.locationDetails?.displayName ?? undefined,
                        place,
                    } as WorkLocationInfo;
                } catch (error) {
                    // if we fail to get Place info, we return the response only with relative work location && workspace info
                    workInfo = {
                        ...workInfo,
                        workspaceId: currentSegment.locationDetails?.id,
                        workspaceName: currentSegment.locationDetails?.displayName ?? undefined,
                        place: undefined,
                    } as WorkLocationInfo;
                }
            }
        }
    } catch (error) {
        perfDatapoint.endWithError(DatapointStatus.ServerError, error);
    } finally {
        return workInfo;
    }
}
