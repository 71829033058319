import type { PerformanceDatapoint } from 'owa-analytics';
import type { CustomWaterfallRange } from 'owa-analytics-types';

const placesFinderPerfCheckpoints = [
    'DefaultBuildingLoaded',
    'HybridspacePlacesFinderPane',
    'BuildingsLoaded',
    'TagsAndFeaturesLoaded',
    'SectionsLoaded',
    'PlacesFinderInternalLoadStart',
] as const;

type PlacesFinderPerfCheckpoint = typeof placesFinderPerfCheckpoints[number];

export default function markPlacesFinderPerfCheckpoint(
    datapoint: PerformanceDatapoint,
    checkpoint: PlacesFinderPerfCheckpoint
) {
    const waterfallIndex = getCheckpointIndex(checkpoint);
    if (!datapoint.hasEnded && !datapoint.isWaterfallCheckpointDefined(waterfallIndex)) {
        datapoint.addToCustomWaterfall(waterfallIndex, checkpoint);
    }
}

/**
 * This function receives a number and narrows its type to CustomWaterfallRange if it is in the range
 * @param index the number to check
 * @returns true if the number is in the CustomWaterfallRange. Narrows down the type to CustomWaterfallRange
 */
function isInCustomWaterfallRange(index: number): index is CustomWaterfallRange {
    return Number.isInteger(index) && index > 0 && index <= 15;
}

/**
 * Gets the checkpoint index in the CustomWaterfallRange type
 * @param checkpoint the checkpoint name
 * @returns a number between 1 and 15 with the type needed to satisfy addToCustomWaterfall
 */
function getCheckpointIndex(checkpoint: PlacesFinderPerfCheckpoint): CustomWaterfallRange {
    const arrayIndex = placesFinderPerfCheckpoints.indexOf(checkpoint);
    const checkpointIndex = arrayIndex + 1; // CustomWaterfallRange starts from 1
    if (!isInCustomWaterfallRange(checkpointIndex)) {
        throw new Error('checkpoint index exceeds the CustomWaterfallRange');
    }
    return checkpointIndex;
}
