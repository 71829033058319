import { DatapointStatus, logGreyError, PerformanceCoreDatapoint } from 'owa-analytics';
import { getNextPaint } from 'owa-analytics-shared';
import { type MailboxInfo } from 'owa-client-types';
import type { OwaDate } from 'owa-datetime';
import { getISODateString } from 'owa-datetime';
import type { CustomCheckpointCode } from 'owa-analytics-types';

export enum WorkPlansDailyPeekPerfWaterfallPoint {
    WorkPlansDailyPeekStartRender = 1,
    WorkLocationsSegmentRendered = 2,
    OthersInOfficeStartRender = 3,
    OthersInOfficeDataReturned = 4,
    OthersInOfficeRendered = 5,
    SuggestionsStartRender = 6,
    SuggestionsRendered = 7,
    SuggestionsCodeStart = 8,
    SuggestionsCodeEnd = 9,
    FirstSuggestionLoaded = 10,
}

let workPlansDailyPeekPerfDataPoint: PerformanceCoreDatapoint | null = null;
let numPeopleToRenderOIO = 0;
let numPeopleRenderedOIO = 0;
let numDailyPeekContainerResizes = 0;
let peopleRenderedTimings: number[] = [];

// Generalized tracking of expected WF markers before allowing DP to end
const defaultExpectedMarkersBeforeEnd = [
    WorkPlansDailyPeekPerfWaterfallPoint.WorkPlansDailyPeekStartRender,
    WorkPlansDailyPeekPerfWaterfallPoint.WorkLocationsSegmentRendered,
];
// Map to track when each WF marker that must be set before ending the Datapoint is notified in the process.
const notifiedMarkersTimings = new Map<WorkPlansDailyPeekPerfWaterfallPoint, number>(
    defaultExpectedMarkersBeforeEnd.map(marker => [marker, 0])
);

//Track suggestion render timings in a generalized way
const suggestionRenderedTimingMap = new Map<string, number>();

export function createWorkPlansDailyPeekPerfDatapoint(
    dateOfWorkPlansDailyPeek: OwaDate,
    customStartTimeForPerfWaterfall: number,
    mailboxInfo: MailboxInfo
) {
    if (workPlansDailyPeekPerfDataPoint?.hasEnded) {
        resetDatapointReferences();
    }

    if (workPlansDailyPeekPerfDataPoint && !workPlansDailyPeekPerfDataPoint.hasEnded) {
        // User dismissed the previous one and opened a new one, so we should end the previous one
        endDatapoint(false);
    }

    if (!workPlansDailyPeekPerfDataPoint) {
        workPlansDailyPeekPerfDataPoint = new PerformanceCoreDatapoint(
            'WorkPlansDailyPeekPerfWaterfall',
            {
                customStartTime: customStartTimeForPerfWaterfall,
                mailbox: mailboxInfo,
            }
        );
        const workPlansDailyPeekDate = getISODateString(dateOfWorkPlansDailyPeek);
        workPlansDailyPeekPerfDataPoint.addCustomData({ workPlansDailyPeekDate });
    }
}

export function setWorkPlansDailyPeekPerfWaterfallState(
    point: WorkPlansDailyPeekPerfWaterfallPoint,
    waitForNextPaint = false,
    discardMarkerIfDefined = true
) {
    const cb = () => {
        workPlansDailyPeekPerfDataPoint?.addToCustomWaterfall(
            point,
            WorkPlansDailyPeekPerfWaterfallPoint[point],
            discardMarkerIfDefined /*discardIfDefined*/
        );

        endDatapointIfAllRequiredWaterfallPointsHit(waitForNextPaint);
    };
    if (waitForNextPaint) {
        getNextPaint(cb);
    } else {
        cb();
    }
}

export function setIncludeOthersInOfficeInPerfWaterfall() {
    expectWaterfallMarkerBeforeEnd(WorkPlansDailyPeekPerfWaterfallPoint.OthersInOfficeRendered);
}

export function setIncludeSuggestionsInPerfWaterfall() {
    expectWaterfallMarkerBeforeEnd(WorkPlansDailyPeekPerfWaterfallPoint.SuggestionsRendered);
    expectWaterfallMarkerBeforeEnd(WorkPlansDailyPeekPerfWaterfallPoint.FirstSuggestionLoaded);
}

export function flagSuggestionRendered(suggestionName: string) {
    if (!!workPlansDailyPeekPerfDataPoint) {
        suggestionRenderedTimingMap.set(
            suggestionName,
            workPlansDailyPeekPerfDataPoint.calculateTotalDuration()
        );
        if (
            !workPlansDailyPeekPerfDataPoint.isWaterfallCheckpointDefined(
                WorkPlansDailyPeekPerfWaterfallPoint.FirstSuggestionLoaded
            )
        ) {
            setWorkPlansDailyPeekPerfWaterfallState(
                WorkPlansDailyPeekPerfWaterfallPoint.FirstSuggestionLoaded,
                true /* waitForNextPaint */,
                false /* discardMarkerIfDefined */
            );
        }
    } else {
        logGreyError('WorkPlansDailyPeekSuggestionRenderedAfterDPEnd', undefined /* error */, {
            suggestionName,
        });
    }
}

/**
 * This function flags that a given Custom Waterfall index (marker) should be defined before ending the datapoint
 * or in other words, we need to wait for it before ending the datapoint.
 *
 * @param marker Custom Waterfall index that we will wait to be defined before ending the datapoint
 */
export function expectWaterfallMarkerBeforeEnd(marker: WorkPlansDailyPeekPerfWaterfallPoint) {
    if (!notifiedMarkersTimings.has(marker) && !!workPlansDailyPeekPerfDataPoint) {
        notifiedMarkersTimings.set(
            marker,
            workPlansDailyPeekPerfDataPoint.calculateTotalDuration()
        );
    }
}

export function getSuggestionsCustomCheckpointCodeForRender(): CustomCheckpointCode | undefined {
    if (!workPlansDailyPeekPerfDataPoint) {
        return undefined;
    }
    return {
        name: 'SuggestionsLayout',
        datapoint: workPlansDailyPeekPerfDataPoint,
        indexes: [
            WorkPlansDailyPeekPerfWaterfallPoint.SuggestionsCodeStart,
            WorkPlansDailyPeekPerfWaterfallPoint.SuggestionsCodeEnd,
        ],
    };
}

export function setSuggestionsModuleAvailable(isAvailable: boolean) {
    workPlansDailyPeekPerfDataPoint?.addCustomData({ isSuggestionsModuleAvailable: isAvailable });
}

export function setNumPeopleToRenderOIOInPerfWaterfall(numPeople: number) {
    if (numPeople !== numPeopleToRenderOIO) {
        workPlansDailyPeekPerfDataPoint?.addCustomData({ numPeopleToRenderSuggestions: numPeople });
        numPeopleToRenderOIO = numPeople;
    }
}

export function countPeopleRenderedOIO() {
    numPeopleRenderedOIO++;
    peopleRenderedTimings.push(Date.now() - (workPlansDailyPeekPerfDataPoint?.getStartTime() ?? 0));
    endDatapointIfAllRequiredWaterfallPointsHit(true);
}

export function countResize() {
    numDailyPeekContainerResizes++;
}

export function isWorkPlansDailyPeekPerfDatapointEnded(): boolean {
    return !workPlansDailyPeekPerfDataPoint || workPlansDailyPeekPerfDataPoint.hasEnded;
}

function areAllExpectedMarkersHit(): boolean {
    for (const marker of notifiedMarkersTimings.keys()) {
        if (!workPlansDailyPeekPerfDataPoint?.isWaterfallCheckpointDefined(marker)) {
            return false;
        }
    }
    return true;
}

const endDatapointIfAllRequiredWaterfallPointsHit = (hasWaitedForNextPaint: boolean) => {
    const cb = () => {
        if (
            workPlansDailyPeekPerfDataPoint &&
            !workPlansDailyPeekPerfDataPoint.hasEnded &&
            areAllExpectedMarkersHit() &&
            numPeopleRenderedOIO >= numPeopleToRenderOIO
        ) {
            endDatapoint(true);
        }
    };
    if (hasWaitedForNextPaint) {
        cb();
    } else {
        getNextPaint(cb);
    }
};

/**
 * Function to construct a plain object from a Map. This is needed because `JSON.stringify` does not work with Maps.
 * @param map Map to be mapped to plain object
 * @returns Plain Object with the same key-value pairs as the input map
 */
const mapToObj = (map: Map<string | number, number>) => {
    const obj: Record<string | number, number> = {};
    for (const key of map.keys()) {
        obj[key] = map.get(key) ?? -1;
    }
    return obj;
};

const endDatapoint = (isNormalEnd: boolean) => {
    if (workPlansDailyPeekPerfDataPoint) {
        if (numPeopleToRenderOIO > 0) {
            workPlansDailyPeekPerfDataPoint.addCustomData({
                numPeopleRenderedOIO,
                peopleRenderedTimings: peopleRenderedTimings.join(','),
            });
        }
        workPlansDailyPeekPerfDataPoint.addCustomData({
            numDailyPeekContainerResizes,
            areAllExpectedMarkersHit: areAllExpectedMarkersHit(),
            notifiedMarkersTimings: JSON.stringify(mapToObj(notifiedMarkersTimings)),
            suggestionRenderedTimings: JSON.stringify(mapToObj(suggestionRenderedTimingMap)),
            isNormalEnd,
        });
        workPlansDailyPeekPerfDataPoint.end(
            undefined /* duration */,
            !isNormalEnd ? DatapointStatus.UserCancelled : undefined /* overrideStatus */
        );
        resetDatapointReferences();
    }
};

const resetDatapointReferences = () => {
    workPlansDailyPeekPerfDataPoint = null;
    numPeopleToRenderOIO = 0;
    numPeopleRenderedOIO = 0;
    numDailyPeekContainerResizes = 0;
    peopleRenderedTimings = [];
    notifiedMarkersTimings.clear();
    for (const marker of defaultExpectedMarkersBeforeEnd) {
        notifiedMarkersTimings.set(marker, 0);
    }
};
