import type RequestState from 'eventify-schema/lib/RequestState';
import { ObservableMap } from 'mobx';
import { createAccessors } from 'owa-viewstate-store';
import { createStore } from 'satcheljs';
import initializeWorkGroupsComposeErrors from '../utils/initializeWorkGroupsComposeErrors';

import type { WorkGroupMinimized } from './schema/MinimizedWorkGroup';

import type EventifyEvent from 'eventify-schema/lib/EventifyEvent';
import type EventsGroup from 'eventify-schema/lib/EventsGroup';
import type EventWithMaster from './schema/EventWithMaster';

// This store encase both the old implementation of the store for places-work-groups and the new implementation that will be used in
// hybridspace-home (people tab). The object Store is getting exported exclusively for the first implementation and SHOULD NOT BE USED
// by any other implementation. This package will also need to pass through a classes cleaning process to minize the amount of code that
// it needs to work. WI: https://outlookweb.visualstudio.com/MicrosoftPlaces/_workitems/edit/287856
const getStore = createStore('places-work-groups-store', {
    currentEvent: null as EventifyEvent | null,
    currentEventsGroup: null as EventsGroup | null,
    originalEventsGroup: null as EventsGroup | null,
    guidanceInstancesWithMasters: null as EventWithMaster[] | null,
    loadGuidancesInstancesRequestState: 0,
    loadAllWorkGroupsRequestState: 0,
    workGroupChangesMade: false,
    workGroupHadDL: false,
    workGroups: new ObservableMap<string, WorkGroupMinimized>(),
    workGroupsComposeErrors: initializeWorkGroupsComposeErrors(),
});

// Because of the reasons explained in line 16-19, this shouldn't be used by any new implementation. Please don't use this object and create
// a getter and setter for any new values added to the store.
export const store = getStore();

export const {
    getCurrentEvent,
    setCurrentEvent,
    getCurrentEventsGroup,
    setCurrentEventsGroup,
    getGuidanceInstancesWithMasters,
    setGuidanceInstancesWithMasters,
    getLoadGuidancesInstancesRequestState,
    setLoadGuidancesInstancesRequestState,
    getLoadAllWorkGroupsRequestState,
    setLoadAllWorkGroupsRequestState,
    getOriginalEventsGroup,
    setOriginalEventsGroup,
    getWorkGroupChangesMade,
    setWorkGroupChangesMade,
    getWorkGroupHadDL,
    setWorkGroupHadDL,
    getWorkGroups,
    getWorkGroupsComposeErrors,
    setWorkGroupsComposeErrors,
} = createAccessors('places-work-groups-store', getStore);
