import { createObservableOperation } from 'owa-observable-operation';
import { getPlacesService } from 'places-service';

const {
    onSucceeded: loadPlaceSucceeded,
    startAsync: invokeLoadPlace,
    onFailed: loadPlaceFailed,
} = createObservableOperation(
    'PLACES_LOAD_LOCATION',
    buildingId => ({ buildingId }),
    ({ buildingId }) => buildingId,
    ({ buildingId }) => getPlacesService().getPlaceFromBuildingId(buildingId)
);

export { invokeLoadPlace, loadPlaceSucceeded, loadPlaceFailed };
