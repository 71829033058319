import { createAccessors } from 'owa-viewstate-store';
import { createStore } from 'satcheljs';

import type { UPSCollaborator } from 'places-ups-location-operations';

const getStore = createStore('places-ups-location-store', {
    // Map of buildingId to list of collaborators in that building
    collaboratorsInOfficeStatus: null as UPSCollaborator[] | null,
    // Map of buildingId to list of collaborators that are in the users meetings and are in that building
    collaboratorsInMeetingsStatus: null as UPSCollaborator[] | null,
    collaboratorsOutOfficeStatus: null as string[] | null,
    lastServicePeopleInOfficeSuccessfullyCompleted: false,
    lastServicePeopleInMeetingsSuccessfullyCompleted: false,
});

export const store = getStore();

const {
    getCollaboratorsInOfficeStatus,
    setCollaboratorsInOfficeStatus,
    getCollaboratorsInMeetingsStatus,
    setCollaboratorsInMeetingsStatus,
    getCollaboratorsOutOfficeStatus,
    setCollaboratorsOutOfficeStatus,
    getLastServicePeopleInOfficeSuccessfullyCompleted,
    setLastServicePeopleInOfficeSuccessfullyCompleted,
    getLastServicePeopleInMeetingsSuccessfullyCompleted,
    setLastServicePeopleInMeetingsSuccessfullyCompleted,
} = createAccessors('places-ups-location-store', getStore);

export {
    getCollaboratorsInOfficeStatus,
    setCollaboratorsInOfficeStatus,
    getCollaboratorsInMeetingsStatus,
    setCollaboratorsInMeetingsStatus,
    getCollaboratorsOutOfficeStatus,
    setCollaboratorsOutOfficeStatus,
    getLastServicePeopleInOfficeSuccessfullyCompleted,
    setLastServicePeopleInOfficeSuccessfullyCompleted,
    getLastServicePeopleInMeetingsSuccessfullyCompleted,
    setLastServicePeopleInMeetingsSuccessfullyCompleted,
};
