import { loadCollaboratorsLocations, loadFWH } from 'places-explore-operations';
import { updateCurrentDateRange } from 'places-user-session-stores';
import { orchestrator } from 'satcheljs';

orchestrator(updateCurrentDateRange, ({ dateRange, shouldUseCollaboratorsOperations }) => {
    loadFWH(dateRange);
    if (shouldUseCollaboratorsOperations) {
        loadCollaboratorsLocations(dateRange);
    }
});
